var ip = window.location.hostname;
var protocol = window.location.protocol == "https:" ? 'https' : 'http';
// export const API_URL = `https://kyochonwebstg.simplexts.com.au:3007/`;
//  export const BASE_URL = `https://kyochonwebstg.simplexts.com.au:3007/`;
export const CALLBACK_URL = `${protocol}://${ip}`
export const API_URL = `${protocol}://${ip}:3004/`;
export const BASE_URL = `${protocol}://${ip}:3004/`;
// export const BASE_URL_LOCAL = `http://192.168.18.8:3001/`;
export const StripeKey = 'pk_test_51KTiSOL6slnBJvxkNnyGl5tbA20HXxf5Ga1POZw75vsQxEbYoVskM9GPMO3nrcR9S1Z6sTNI2zTomyVdDlkYVS3F00AdhsGZGz' //for stripe testing account
// export const GoogleMapsAPI = 'AIzaSyDTaA9BspUUJtGr_9oN7u5wEaKQIvq3XyE';  //for Simplex solution
export const GoogleMapsAPI = 'AIzaSyD63nbTLBPArnmu6jpOvukRHxo5QbCFRrY';  //for Kyuchon solution
export const priceunit = 'AED '
export const encryptionKey = 'AIzaSyD63nbTLBPArnmu6jpOvukRHxo5QbCFRrY';  //for Kyuchon solution