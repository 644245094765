import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import CheckoutComponent from "../components/Checkout/newPayment";
import { connect } from "react-redux";

class Payments extends Component<{history?:any, location?:any},{}> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount(){
    document.title = "Checkout - Kyochon"
  }
  render() {
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history} />
          <CheckoutComponent location={this.props.location} history={this.props.history} saveOrderk2g={function (data: any, store: any, tax: any, paramstoken?: any): {} {
            throw new Error("Function not implemented.");
          } } successStatus={undefined} paymentSuccess={undefined} />
          <Footer />
        </div>
      </div>
    );
  }
}
export default connect(null, null)(Payments);
