import React, { Component } from "react";
// import LoginComponent from "../components/Login/index";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Emails from "../components/Customer/newsletter-emails";
import { Redirect } from "react-router-dom";

class NewsLetterMails extends Component<{ history: any }, {}> {
    componentDidMount() {
        // document.title = "Mailing List - Tortilla";
        document.title = "Mailing List - Kyochon"

    }
    render() {
        // if (!localStorage.mainToken) {
        //     return <Redirect to="/landing" />
        // }
        return (
            <div className="page-wrapper">
                <div id="page-wrap">
                    <Header history={this.props.history} />
                    <Emails />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default NewsLetterMails;