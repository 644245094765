import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { API_URL, BASE_URL } from '../../ApiManager/client-config';
import './landing.css';
import { Button, Modal as BsModal } from 'react-bootstrap';
import Map from "./map";
import Geocode from "react-geocode";
import Select from 'react-select';
import axios from "axios";
import i18next from "i18next";
import { connect } from "react-redux";
import { locationlandingProps, locationlandingStates } from '../Interface/loginInterface';
import { findStoreforLSM, menuGroupsListHome, menuGroupsList, PickupStoreList, saveSelectStoreId, findPickupStores, saveGuestAddress, saveStore, saveOrderType, addressesListk, TopDealsHome, TopList, TopItemsList, PickupStoresList, selectMenuForPickup, getCart, saveCart, resetCoupon, addAddressUser, saveAddressExtraDetails, findLocalStores, PickupStoreListByDistance, getKmlShape } from '../../Redux/Actions';
import GoogleMap from './newMap';
import * as geolib from 'geolib';
import GoogleNewMap from './googleMap';
import _, { random } from 'lodash';
import { isToken } from 'typescript';
import { DistanceMatrixService } from '@react-google-maps/api';
import UseTrackEvent from '../../hooks/useTrackHook';

class locationlanding extends Component<locationlandingProps, locationlandingStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            target: "",
            windowWidth: window.innerWidth,
            isOpen: false,
            lat: "",
            pickedFromSavedAddress: false,
            room: '',
            buildingname1: '',
            locationEnabled:false,
            buildingname: '',
            lng: "",
            channel: "",
            selectedAddress: "",
            address: "",
            searchString: "",
            searchString2: "",
            placesSuggestion: [],
            changeLocation: false,
            area_details: '',
            location: "",
            position: {},
            filteredStores: [],
            allStores: [],
            pickupStoreSelectFlag: true,
            randomImage: "",
            use_pickup_map:false,
            kml:"",
            directions:null,
            origin: null,
            addEvent: (data: any) => {}
            
        }
    }

    componentDidMount() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                this.setState({origin:{lat:position.coords.latitude,lng:position.coords.longitude}})
                this.props.getKmlShape(position.coords.latitude,position.coords.longitude)
                axios.get(`${API_URL}menu/stores/1`).then((resp:any)=>{
                    if(resp.data.success) {
                        let data:any;
                        let Responedata = resp.data.successResponse;
                        for (let i = 0; i < Responedata.length; i++) {
                            if (Responedata[i].delivery_zone_id.kml_json) {
                                if (geolib.isPointInPolygon({ latitude: position.coords.latitude, longitude: position.coords.longitude }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
                                    data = Responedata[i].delivery_zone_id.kml_shape
                                    this.setState({kml:data})
                                }
                            }
                        }
                    }
                }).catch((err:any)=>{
                    console.log(err)
                })
            })

        }
        let image = this.randomImage();
        this.setState({ randomImage: image })
        let orderType = sessionStorage.getItem('orderType');
        if (orderType == 'qr') {
            this.props.history.push({ pathname: '/menu', search: window.location.search })
        }
        // this.props.PickupStoresList();
        console.log("navigator",navigator)
        navigator.geolocation.getCurrentPosition(position => {
            this.setState({locationEnabled: true})
            console.log(position)
          }, error => {
              console.error(error)
              this.setState({use_pickup_map:false,locationEnabled: false})
          })
        // if ("geolocation" in navigator) {
        //     navigator.geolocation.getCurrentPosition((position: any) => {
        //       console.log("current position call if", position)
        //       this.setState({ position: { lat: position.coords.latitude, lng: position.coords.longitude } })
        //       let data:any = {
        //         brand_id:1,
        //         lat:position.coords.latitude,
        //         lng:position.coords.longitude
        //     }
        //     this.props.PickupStoreListByDistance(data)
        //     })
        //   } else {
        //     console.log("current position call else")
        //     this.props.PickupStoresList();
        //   }
        
        window.addEventListener("resize", this.updateDimensions);
        const token = localStorage.getItem("token");
        if (token) {
            this.props.addressesListk();
        }
        if (!_.isEmpty(this.props.coupon)) {
            this.props.resetCoupon({})
        }
        let cartData: any = this.props.cart;
        console.log("cartData",cartData)
        if (cartData) {
            let cart: any = cartData;
            if (cart.length > 0) {
                let emptyCart: any = [];
                this.props.saveCart(emptyCart);
                this.props.getCart();
            }
        }
        const script = document.createElement("script");

        script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
        script.async = true;

        document.body.appendChild(script);
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        if (nextProps.pickupStoresList !== this.props.pickupStoresList) {
            for(let i = 0;i<nextProps.pickupStoresList.length;i++) {
                Object.assign(nextProps.pickupStoresList[i],{is_clicked:false})
            }
            this.setState({ filteredStores: nextProps.pickupStoresList, allStores: nextProps.pickupStoresList })
        }
        return true;

    }

    componentWillUnmount(): void {
        this.setState({kml:''})
        window.removeEventListener("resize", this.updateDimensions);
    }

    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth });
    }


     // add this handle
    handleSetAddEvent = (eventFunc:any) => {
        this.setState({ addEvent: eventFunc });
    }
    handleSelectChannel = (channel: any) => {
        this.setState({ channel: channel })
        this.props.saveOrderType(channel)
        sessionStorage.setItem("orderType", channel);
        this.setState({ isOpen: true })
        let doc = document as any;
        let o;
        doc && doc.getElementsByTagName("html")[0] ? doc.getElementsByTagName("html")[0].style.overflow = 'unset' : o = '';
        
         //below
        try {
            this.state.addEvent({
            category: "events",
            events: { event_name: `click on ${channel} button` }
            });
        } catch (error) {
            console.error("error while saving event on header page", error);
        }
    }

    handleChangeAddressPickup = (event: any) => {
        this.setState({ searchString: event.target.value });
    }

    handleInputChange = (event: any) => {
        let queryString = event.target.value;
        this.setState({ searchString: event.target.value })
        if (queryString.length > 0 && queryString.length < 50) {
            let obj = {
                address: queryString
            }
            const lang = localStorage.getItem('lang') || 'en';
            axios.post(`${BASE_URL}customer/autoComplete/${lang}/${queryString}`)
                .then((res: any) => {
                    this.setState({ placesSuggestion: res.data.successResponse })
                })
                .catch((err: any) => {
                    alert("something went wrong!!!")
                })
        }
        else {
            this.setState({ placesSuggestion: [] })
        }
    }


    handleStoreInputChange = (e: any) => {
        if (e && e.value > 0) {
            let { pickupStoresList } = this.props;
            let result: any = pickupStoresList.find((x: any) => x.store_id == e.value); //find the store from the stores list
            this.props.saveSelectStoreId(result);
            this.props.saveStore(result);
            this.setState({ searchString: e.value });
        } else {
            this.setState({ searchString: "" });
        }
    };
    handleStores = () => {
        this.props.findPickupStores(
            this.state.lat,
            this.state.lng,
            this.state.searchString
        );
    };

    onPlaceClick = (place: any) => {
        this.setState({ searchString: place.structured_formatting.main_text,kml:'' })
        Geocode.fromAddress(place.description)
            .then((json: any) => {
                var location = json.results[0].geometry.location;
                this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })
                this.props.getKmlShape(location.lat,location.lng)
                this.setState({kml:this.props.kml_shape})
            })
            .catch((error: any) => {
                // console.warn(error));
            });
    }

    handleMapData = (obj: any) => {
        // to Get the values from the map component and set in state
        this.props.saveGuestAddress(obj.address);
        // this.props.findLocalStores(obj.lat, obj.lng);
        this.setState({
            selectedAddress: obj.address,
            searchString: obj.address,
            lat: obj.lat,
            lng: obj.lng,
            position: { lat: obj.lat, lng: obj.lng }
        });
        // axios.get(`${API_URL}menu/stores/1`).then((resp:any)=>{
            // if(resp.data.success) {
                // let data:any;
                // let Responedata = this.props.pickupStoresList;
                // for (let i = 0; i < Responedata.length; i++) {
                //     if (Responedata[i].delivery_zone_id.kml_json) {
                //         console.log("Responedata[i] check",Responedata[i])
                //         if (geolib.isPointInPolygon({ latitude: obj.lat, longitude: obj.lng }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
                //             data = Responedata[i].delivery_zone_id.kml_shape
                //             this.setState({kml:data})
                //         }
                //     }
                // }
            // }
        // }).catch((err:any)=>{
        //     console.log(err)
        // })
        sessionStorage.setItem("changeLat", obj.lat)
        sessionStorage.setItem("changeLng", obj.lng)
    };

    confirmLocation = (e: any) => {
        if (!this.state.pickedFromSavedAddress ) {
            console.log("status if confirm location")
            let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
            let timeZoneOffset = -(timezone);
            let today = new Date();
            let todayDay = today.getDay();
            let data = {
                timeZoneOffset: timeZoneOffset,
                day: todayDay
            }
            this.setState({ isOpen: false })
            this.checkAddress()
            this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
            // this.props.saveCoordinates(obj);
            // this.props.selectChannel(channel);
            this.props.history.push({ pathname: '/menu', search: window.location.search })
        } else {
            console.log("status else confirm location")
            let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
            let timeZoneOffset = -(timezone);
            let today = new Date();
            let todayDay = today.getDay();
            let data = {
                timeZoneOffset: timeZoneOffset,
                day: todayDay
            }
            this.setState({ isOpen: false })
            this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
            this.props.history.push({ pathname: '/menu', search: window.location.search })
        }

    }


    confirmPickupLocation = (e: any) => {
        let { storeSelected } = this.props;
        let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
        let timeZoneOffset = -(timezone);
        let today = new Date();
        let todayDay = today.getDay();
        let data = {
            timeZoneOffset: timeZoneOffset,
            day: todayDay
        }
        sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
        this.props.selectMenuForPickup(storeSelected, data);
        this.props.saveStore(storeSelected)
        this.setState({ isOpen: false })
        this.props.history.push({ pathname: '/menu', search: window.location.search })
    }
    checkAddress() {
        let { addressData } = this.props;

        // if (addressData?.length == 0) {
        //     let obj = {
        //         place: "Home",
        //         full_address: this.props.address_save,
        //         extra_details: this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
        //     };
        //     this.props.addAddress(obj);
        //     return;
        // } else if (this.state.changeLocation) {
        //     let obj = {
        //         place: this.state.description !== '' ? this.state.description : 'Other',
        //         full_address: this.props.address_save,
        //         extra_details: this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
        //     }
        //     this.props.addAddress(obj)
        //     return
        // }
        if (this.state.changeLocation) {
            let obj = {
                place: this.state.description !== '' ? this.state.description : 'Other',
                full_address: this.state.selectedAddress,
                extra_details: this.state.area_details + " " + this.state.buildingname + ' ' + this.state.room
            }
            this.props.addAddress(obj)
            return
        }
    }
    // setDeliveryAddress(data: any) {
    //     this.props.saveGuestAddress(data.full_address);
    //     Geocode.fromAddress(data.full_address)
    //         .then((json: any) => {
    //             var location = json.results[0].geometry.location;
    //             this.setState({
    //                 selectedAddress: data.full_address,
    //                 lat: location.lat,
    //                 lng: location.lng
    //             });
    //             // this.setState({ lat: location.lat, lng: location.lng })
    //             // this.props.findLocalStores(location.lat, location.lng);
    //         })
    //         .catch((error: any) => {
    //             // console.warn(error));
    //         });
    // }

    randomImage = () => {
        let imageArray = ["assets/images/landing_mobile_1.jpg", "assets/images/landing_mobile_2.jpg", "assets/images/landing_mobile_3.jpg"];
        let randomItem = imageArray[Math.floor(Math.random() * imageArray.length)];
        console.log("randomImage", randomItem)
        return randomItem
    }


    exploreMenu = () => {
        this.props.menuGroupsList("", "Delivery")
        this.props.menuGroupsListHome("", "Delivery")
        this.props.TopDealsHome(null, "")
        this.props.TopList(null, "");
        this.props.TopItemsList(null, "");
        this.props.history.push({ pathname: '/menu', search: window.location.search })
    }

    openModal = () => {
        this.setState({ isOpen: true });
    }
    closeModal = () => {
        this.setState({ isOpen: false, changeLocation: false })
        let filter = this.state.filteredStores
        for(let i = 0;i<filter.length;i++){
               filter[i].is_clicked = false 
        }
        this.setState({filteredStores:filter,use_pickup_map:false,directions:null})
    }

    selectPickupStore = (store: any,index?:any) => {
        console.log("sssss",store,index)
        let filter = this.state.filteredStores
        for(let i = 0;i<filter.length;i++){
            if(i == index) {
                filter[i].is_clicked = true
            } else {
               filter[i].is_clicked = false 
            }
        }
        this.setState({filteredStores:filter,use_pickup_map:false,directions:null})
        let doc = document as any;
        var deActiveItem = doc.getElementsByClassName("item")
        for (let i = 0; i < deActiveItem.length; i++) {
            deActiveItem[i].classList.remove("active-item1")
        }
        let activeItem = (document as any).getElementById(store.store_id);
        activeItem.classList.add("active-item1");
        this.setState({ pickupStoreSelectFlag: false })
        this.props.saveSelectStoreId(store);
        this.props.saveStore(store);
    }
    clearField = () => {
        let storesArr: any = this.props.stores;
        this.setState({ filteredStores: storesArr, searchString: "" })
    }
    inputfilterOnChange = (event: any) => {
        const { value } = event.target;
        let { filteredStores } = this.state;
        let storesArr: any = [];
        this.setState({ searchString2: value });
        storesArr = filteredStores;
        if (value == '') {
            this.setState({ filteredStores: this.state.allStores })
        } else {
            const filterdata = this.state.allStores.filter((store: any) => {
                return (
                    (store.address && store.address.toLowerCase().includes(value.toLowerCase())) ||
                    (store.city && store.city.toLowerCase().includes(value.toLowerCase())) ||
                    (store.store_name && store.store_name.toLowerCase().includes(value.toLowerCase()))
                );
            });
            this.setState({ filteredStores: filterdata });
        }
    };

    renderSelectPickup = () => {
        let { stores, pickupStoresList } = this.props;
        let list: any = pickupStoresList
        let store_list: any = [{ label: "Select store", value: "" }]
        console.log(stores, "storesss")
        list.map((store: any) => {
            store_list.push({ label: store.store_name, value: store.store_id })
        })
        const lang = localStorage.getItem('lang') || 'en'
        console.log("stores_List", store_list)
        let { filteredStores } = this.state;
        return (
            <>
                <BsModal show={this.state.isOpen} onHide={this.closeModal}>
                    <BsModal.Header className='modal-pickup-header'>
                        <h4 className="mt-0 mb-0 pickup-text">{i18next.t("landingPage.select_pickup_text")}</h4>

                        <button type="button" data-dismiss="modal" onClick={this.closeModal} aria-label="Close" style={{ cursor: "pointer", border: "none", backgroundColor: "transparent", fontSize: "27px", position: "absolute", right:lang=="eng"? "7px" :"0px" }}>
                            <span className="iconify" data-icon="ci:close-small" ></span>

                        </button>

                    </BsModal.Header>
                    <div className="container searchDesign">
                        <div className="input-group W-90 d-flex align-items-center">

                            <input type="search"
                                name="searchstring"
                                value={this.state.searchString2}
                                className="form-control custom-input"
                                placeholder={lang=="en"?"Search":"بحث"}
                                onChange={this.inputfilterOnChange}
                                style={{ height: '3rem', fontSize: '16px' }}
                            />
                        </div>
                        {/* <i onClick={this.clearField} className="fa fa-times close-icon-times"></i> */}
                    </div>
                    {/* {this.state.filteredStores && this.state.length > 0 &&
                                <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchdata} aria-hidden="true"></i>
                            } */}
                    <BsModal.Body>
                        {/* <BsModal.Header className='modal-pickup-header'>
                            <p className='delievery-pickup-subtitle'>Select Outlet</p>

                        </BsModal.Header> */}


                        {/* <div className="flat-list-container">
                            {this.state.filteredStores && this.state.filteredStores.map((store: any) => (

                                <Select
                                    // id={`${store.store_id}`}
                                    closeMenuOnSelect={true}
                                    name="searchString"
                                    placeholder="Select outlet"
                                    // value={{label:'Select outlet',}}
                                    isClearable
                                    options={list}
                                    className=""
                                    // classNamePrefix="select"
                                    onChange={() => this.selectPickupStore(store)}
                                />
                            ))}
                        </div> */}
                        <div className="flat-list-container">
                            {this.state.filteredStores && this.state.filteredStores.map((store: any,index:any) => (
                                <div id={`${store && store.store_id}`} onClick={() => this.selectPickupStore(store,index)} className="item">
                                    <span className='myprofile' style={{ fontSize: '17px', fontWeight: "bold" }}>{store && lang =='ar' ? store.store_name_ar ? store.store_name_ar :store.store_name : store.store_name}</span>
                                    <br />
                                    <span style={{display:"block"}} className='myprofile'>{store && store.use_mannual_address == 1 && store.mannual_address != '' ? store.mannual_address : store && store.address}</span>
                                    {this.state.locationEnabled && <><span className='myprofile' style={{ fontSize: '17px', fontWeight: "bold" }}>Distance to store:</span> <span>{this.haversine(this.state.origin.lat,this.state.origin.lng, store.lat,store.lng)}</span></>}
                                    {store && store.is_clicked && this.state.locationEnabled && <div className='mt-2' style={{display:'flex',justifyContent:'center'}}>
                                        <button disabled={this.state.pickupStoreSelectFlag} onClick={()=>window.open("https://maps.google.com?q="+store.lat+","+store.lng)} className="btn get_direction_btn">
                                            {lang == 'en' ? "Open Map" : "افتح الخريطة"}
                                        </button>
                                    </div>}
                                    
                                </div>
                            ))}
                        </div>

                    </BsModal.Body>
                    <BsModal.Footer className="justify-content-center modal-footer1">
                        <Button disabled={this.state.pickupStoreSelectFlag} onClick={this.confirmPickupLocation} className="btn cart-button pickup-button">
                        {i18next.t("landingPage.start_order_text")}
                        </Button>
                    </BsModal.Footer>
                </BsModal>
                {/* <button onClick={this.confirmLocation} className="btn cart-button">Continue</button> */}
            </>
        )
    }
    renderSelectPickupMap = () => {
        const lang = localStorage.getItem('lang') || 'en'
        const token = localStorage.getItem("token");
        let { addressData, orderType, stores } = this.props;
        let last2AddressData: any = addressData && addressData.length > 2 ? addressData?.slice((addressData.length - 2), addressData.length) : addressData
        let { placesSuggestion } = this.state;
        return (
            <div className="row">
                <div className="col-lg-6">
                    {/* <Autocomplete
                            apiKey={GoogleMapsAPI}
                            className="autocomplete-input"
                            // google={this.props.google}
                            placeholder={"Enter your nearby location"}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}   //types={['(regions)']}
                            componentRestrictions={{ country: "pk" }}
                            /> */}

                </div>

                <BsModal show={this.state.isOpen} onHide={this.closeModal}>
                    <BsModal.Header className='modal-header' closeButton>
                        <BsModal.Title>
                            <p className='delievery-title '>{i18next.t("landingPage.select_pickup_text")}</p>
                            {/* <p className='delievery-subtitle'>{i18next.t("landingPage.full_address_text")}</p> */}

                        </BsModal.Title>
                        <div className="search-wrapper">
                            {/* <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleInputChange} /> */}
                            {this.state.placesSuggestion.length > 0 &&
                                <div className="address-suggestion">
                                    <ul>
                                        {this.state.placesSuggestion.map((place: any) => {
                                            return (
                                                <>
                                                    <li
                                                        onClick={() => this.onPlaceClick(place)}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="upper-text">
                                                            <strong>
                                                                {place.structured_formatting.main_text}
                                                            </strong>
                                                        </span>
                                                        <span className="bottom-text">
                                                            {place.structured_formatting.secondary_text}
                                                        </span>
                                                    </li>
                                                </>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                            {this.state.placesSuggestion && this.state.placesSuggestion.length > 0 &&
                                <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                            }
                            {/* <img style={{ width: '30px', height: '30px', cursor: "pointer" }} className="" title="Current Location" src="assets/images/MapPin.svg" alt="pin"
                                onClick={() => { this.getCurrentPositionMark() }}
                            /> */}

                        </div>
                    </BsModal.Header>

                    <BsModal.Body style={{ overflowX: 'hidden' }}>
                        {/* <Map
                            // data={this.props.address_save !== "" ? data : ""}
                            onSetData={this.handleMapData}
                            location={this.state.position}
                            google={this.props.google}
                            height="300px"
                            zoom={17}
                        /> */}
                        {/* <GoogleMap
                            onSetData={this.handleMapData}
                            location={this.state.position}
                        /> */}
                        
                        {/* <Button onClick={this.confirmPickupLocation} className="btn cart-button map-button">
                            {i18next.t("landingPage.start_order_text")}
                        </Button> */}


                    </BsModal.Body>
                    <BsModal.Footer className='modal-footer2' style={{ display: "unset" }}>
                        <div className='row'>
                            <div className="flat-list-container">
                                {this.state.filteredStores && this.state.filteredStores.map((store: any) => (
                                    <div id={`${store.store_id}`} onClick={() => this.selectPickupStore(store)} className="item">
                                        <span className='myprofile' style={{ fontSize: '17px', fontWeight: "bold" }}>{store.store_name}</span>
                                        <br />
                                        <span className='myprofile'>{store.use_mannual_address == 1 && store.mannual_address !='' ? store.mannual_address :store.address}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <Button disabled={this.state.pickupStoreSelectFlag} onClick={this.confirmPickupLocation} className="btn cart-button pickup-button">
                            {i18next.t("landingPage.start_order_text")}
                        </Button>
                        
                    </BsModal.Footer>
                </BsModal>

            </div>
        )
    }
    handleAddressInputChange = (event: any) => {

        if (event.target.value == ' ') {
            event.target.value = event.target.value.replace(/\s/g, "");
        }
        this.setState({ [event.target.name]: event.target.value });
    };
    getCurrentPositionMark = () => {

        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
                console.log("current position call", position)
                this.setState({ position: { lat: position.coords.latitude, lng: position.coords.longitude } })
                // axios.get(`${API_URL}menu/stores/1`).then((resp:any)=>{
                    // if(resp.data.success) {
                        let data:any;
                        let Responedata = this.props.pickupStoresList;
                        for (let i = 0; i < Responedata.length; i++) {
                            if (Responedata[i].delivery_zone_id.kml_json) {
                                if (geolib.isPointInPolygon({ latitude: position.coords.latitude, longitude: position.coords.longitude }, JSON.parse(Responedata[i].delivery_zone_id.kml_json))) {
                                    data = Responedata[i].delivery_zone_id.kml_shape
                                    console.log("Responedata[i] check",Responedata[i],data)
                                    this.setState({kml:data})
                                }
                            }
                        }
                    // }
                // }).catch((err:any)=>{
                //     console.log(err)
                // })
            })
        }
    };

    removeSearchString = () => {
        this.setState({ searchString: "", placesSuggestion: [] })
    }
    removeSearchdata = () => {
        this.setState({ searchString: "", filteredStores: [] })
    }

    setDeliveryAddress(data: any) {
        console.log('data saved location', data);

        this.setState({ pickedFromSavedAddress: true })
        this.props.saveGuestAddress(data.full_address);
        sessionStorage.setItem("address_id", data.address_id);
        sessionStorage.setItem("customer_address",data.full_address)
        this.props.saveAddressExtraDetails(data.extra_details)
        Geocode.fromAddress(data.full_address)
            .then((json: any) => {
                console.log("json", json)
                var location = json.results[0].geometry.location;
                this.setState({ lat: location.lat, lng: location.lng, searchString: json.results[0].formatted_address });
                this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })
                this.props.getKmlShape(location.lat,location.lng)
                this.setState({kml:this.props.kml_shape})
                sessionStorage.setItem("changeLat", location.lat)
                sessionStorage.setItem("changeLng", location.lng)
                // this.props.findLocalStores(location.lat, location.lng);
            })
            .catch((error: any) => {
                // console.warn(error));
            });
    }
   

    renderSelectDelivery = () => {
        const lang = localStorage.getItem('lang') || 'en'
        const token = localStorage.getItem("token");
        let { addressData, orderType, stores } = this.props;
        let last2AddressData: any = addressData && addressData.length > 2 ? addressData?.slice((addressData.length - 2), addressData.length) : addressData
        let { placesSuggestion } = this.state;
        return (
            <div className="row">
                <div className="col-lg-6">
                    {/* <Autocomplete
                            apiKey={GoogleMapsAPI}
                            className="autocomplete-input"
                            // google={this.props.google}
                            placeholder={"Enter your nearby location"}
                            onPlaceSelected={this.onPlaceSelected}
                            types={['address']}   //types={['(regions)']}
                            componentRestrictions={{ country: "pk" }}
                            /> */}

                </div>

                <BsModal show={this.state.isOpen} onHide={this.closeModal}>
                    <BsModal.Header className='modal-header' closeButton>
                        <BsModal.Title>
                            <p className='delievery-title '>{i18next.t("landingPage.select_delivery_text")}</p>
                            <p className='delievery-subtitle'>{i18next.t("landingPage.full_address_text")}</p>

                        </BsModal.Title>
                        <div className="search-wrapper">
                            <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleInputChange} />
                            {this.state.placesSuggestion.length > 0 &&
                                <div className="address-suggestion">
                                    <ul>
                                        {this.state.placesSuggestion.map((place: any) => {
                                            return (
                                                <>
                                                    <li
                                                        onClick={() => this.onPlaceClick(place)}
                                                        className="suggestion-item"
                                                    >
                                                        <span className="upper-text">
                                                            <strong>
                                                                {place.structured_formatting.main_text}
                                                            </strong>
                                                        </span>
                                                        <span className="bottom-text">
                                                            {place.structured_formatting.secondary_text}
                                                        </span>
                                                    </li>
                                                </>
                                            )
                                        })
                                        }
                                    </ul>
                                </div>
                            }
                            {this.state.searchString.length > 0 &&
                                <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                            }
                            <img style={{ width: '30px', height: '30px', cursor: "pointer" }} className="" title="Current Location" src="assets/images/MapPin.svg" alt="pin"
                                onClick={() => { this.getCurrentPositionMark() }}
                            />

                        </div>
                    </BsModal.Header>

                    <BsModal.Body style={{ overflowX: 'hidden' }}>
                        {/* <Map
                            // data={this.props.address_save !== "" ? data : ""}
                            onSetData={this.handleMapData}
                            location={this.state.position}
                            google={this.props.google}
                            height="300px"
                            zoom={17}
                        /> */}
                        <GoogleMap
                            onSetData={this.handleMapData}
                            location={this.state.position}
                            kml_shape={this.state.kml}
                            stores={this.props.pickupStoresList}
                            lat={this.state.lat}
                            lng={this.state.lng}

                        // google={this.props.google}
                        // center={{ lat: this.state.lat, lng: this.state.lng }}
                        // store_id={this.state.store_id}
                        // kml={this.state.kml}
                        />
                        {/* <GoogleNewMap
                        // onSetData={this.handleMapData}
                        location={this.state.position}
                        google={this.props.google}
                        /> */}
                        <Button disabled={this.state.searchString == '' ? true : false} onClick={this.confirmLocation} className="btn cart-button map-button">
                            {lang=="en"?"START YOUR ORDER" :"ابدأ طلبك"}
                        </Button>


                    </BsModal.Body>
                    <BsModal.Footer className='modal-footer2' style={{ display: "unset" }}>
                        <div className="row">

                            {last2AddressData &&
                                last2AddressData.length > 0 &&
                                orderType == "Delivery" &&
                                last2AddressData.map((obj: any, index: any) => {
    // =======
                                    return (
                                        //  =======
                                        <div className="col-6">
                                            <div className="d-flex"
                                                onClick={() => this.setDeliveryAddress(obj)}
                                            >
                                                {lang !=="ar" &&<div>
                                                    <input
                                                        type="radio"
                                                        id="pick"
                                                        name="store"
                                                        value="address"
                                                        {...(this.state.selectedAddress == obj.address_id && {
                                                            checked: true,
                                                        })}
                                                        style={{ marginRight: "15px", marginTop: "6px" }}
                                                    />
                                                </div>}
                                              
                                                
                                                <div className=''>

                                                    <strong className='modal-address-content'>{obj.place}</strong>

                                                    <span className='modal-address-details'>
                                                        {obj.extra_details &&
                                                            <>
                                                                <br />
                                                                {obj.extra_details}
                                                            </>
                                                        }
                                                        {obj.full_address &&
                                                            <>
                                                                <br />
                                                                {obj.full_address}
                                                            </>
                                                        }
                                                    </span>

                                                </div>
     
                                                { lang=="ar" &&  <div>
                                                    <input
                                                        type="radio"
                                                        id="pick"
                                                        name="store"
                                                        value="address"
                                                        {...(this.state.selectedAddress == obj.address_id && {
                                                            checked: true,
                                                        })
                                                       
                                                    
                                                }
                                                       
                                                        style={{ marginRight: "15px", marginTop: "6px" }}
                                                    />
                                                </div>}
                                            </div>
                                        </div>
                                    );
                                }
                                )}
                            {token &&
                                <div className="col-12 my-4">
                                    <div
                                        className={lang!=="ar"?"d-flex add-another-address-button":"d-flex justify-content-center"}

                                        onClick={() => { this.setState({ changeLocation: !this.state.changeLocation }) }}
                                    >

                                       {lang!=="ar"&& <img style={{ width: '14px', height: '14px', cursor: "pointer" }} className="" title="Plus Icon" src="assets/images/plusicon.svg" alt="add Icon"
                                        />}

                                        {/* <input
                                            // type="radio"
                                            id="changeLoc"
                                            name="changelocation"
                                            value="changelocation"
                                            {...(this.state.changeLocation && {
                                                checked: true,
                                            })}
                                            // {...(this.state.selectedAddress == obj.address_id && {
                                            //   checked: true,
                                            // })}
                                            style={{ marginRight: "15px" }}
                                        />
                                         */}
                                        <strong className='modal-address-details' style={{ color: "#8A2432", cursor: "pointer", marginLeft: "1rem", fontWeight: "bolder" }}>{lang=="en"?"Add another address":"أضف عنوان آخر"}</strong>
                                        {lang=="ar"&& <img style={{ width: '14px', height: '14px', cursor: "pointer" }} className="" title="Plus Icon" src="assets/images/plusicon.svg" alt="add Icon"
                                        />}
                                         <br />
                                    </div>
                                </div>
                            }
                        </div>
                        {(token && (this.state.changeLocation)) && (
                            <>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang=="en"?"Area":"منطقة"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1"
                                            name="area_details"
                                            value={this.state.area_details}
                                            // placeholder="Villa/Flat #"
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang=="en"?"Building Name/Building Number":"رقم المبنى/اسم المبنى"}{" "}
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1 "
                                            name="buildingname"
                                            // placeholder="Building Name/Building Number"
                                            value={this.state.buildingname}
                                            required
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <strong className='modal-address-content'>
                                            {lang=="en"?"Villa/Flat No.":"فيلا/شقة لا"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}>*</sup>
                                        </strong>
                                        <input
                                            id="roundb"
                                            style={{ fontSize: "15px", height: "0rem" }}
                                            type="text"
                                            className="form-control mt-1"
                                            name="room"
                                            value={this.state.room}
                                            // placeholder="Villa/Flat #"
                                            onChange={(e) => {
                                                this.handleAddressInputChange(e);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-md-12">
                                        <strong className='modal-address-content'>
                                            {lang=="en"?"Description":"الوصف"}{" "}
                                            <sup style={{ color: "red", fontSize: "12px" }}></sup>
                                        </strong>
                                        <div className="cart-table-container">
                                            <div className="iwant-sec">
                                                <ul className="iwnt-toggleButton seconds mt-1">
                                                    <li onChange={() => this.setState({ description: "Home" })}>
                                                        <input
                                                            type="radio"
                                                            id="home"
                                                            name="drone"
                                                            value="Home"
                                                            {...(this.state.description == "Home" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="home"
                                                            className="my-1 font-weight-bold modal-address-details "
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.home')}
                                                        </label>
                                                    </li>
                                                    <li onChange={() => this.setState({ description: "Office" })}>
                                                        <input
                                                            type="radio"
                                                            id="office"
                                                            name="drone"
                                                            {...(this.state.description == "Office" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="office"
                                                            className="my-1 font-weight-bold modal-address-details"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.office')}
                                                        </label>
                                                    </li>
                                                    <li onChange={() => this.setState({ description: "Other" })}>
                                                        <input
                                                            type="radio"
                                                            id="other"
                                                            name="drone"
                                                            {...(this.state.description == "Other" && {
                                                                checked: true,
                                                            })}
                                                            style={{ marginRight: "15px" }}
                                                        />
                                                        <label
                                                            htmlFor="other"
                                                            className="my-1 font-weight-bold modal-address-details"
                                                            style={{ fontSize: "12px" }}
                                                        >
                                                            {i18next.t('manageAddress.other')}
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </>
                        )}
                    </BsModal.Footer>
                </BsModal>

            </div>
        )
    }
    //find distance of two coordinates
    haversine = (
        locationLat: any,
        locationLong: any,
        storeLat: any,
        storeLong: any
    ) => {
        // https://www.geeksforgeeks.org/program-distance-two-points-earth/
       // The math module contains a function
		// named toRadians which converts from
		// degrees to radians.
		locationLong = locationLong * Math.PI / 180;
		storeLong = storeLong * Math.PI / 180;
		locationLat = locationLat * Math.PI / 180;
		storeLat = storeLat * Math.PI / 180;

		// Haversine formula
		let dlon = storeLong - locationLong;
		let dlat = storeLat - locationLat;
		let a = Math.pow(Math.sin(dlat / 2), 2)
				+ Math.cos(locationLat) * Math.cos(storeLat)
				* Math.pow(Math.sin(dlon / 2),2);
			
		let c = 2 * Math.asin(Math.sqrt(a));

		// Radius of earth in kilometers. Use 3956
		// for miles
		let r = 6371;
		// calculate the result
		return `${Math.round(c*r)} Km`;
    };
    renderContent = () => {
        if (this.state.channel === "Delivery") {
          let windowObj = window as any;
          windowObj.dataLayer.push({ desc: null });
          windowObj.dataLayer.push({
            event: "confirm_location_clicked",
            desc: "Confirm Location",
          });
          return this.renderSelectDelivery();
        } else if (this.state.channel === "Pickup") {
          return this.renderSelectPickup();
        } else {
          return null;
        }
    }

    render() {
        // let maintoken = localStorage.getItem("mainToken")
        // if (!maintoken) {
        //     this.props.history.push("/")
        // }
        let canonicalUrl = window.location.href
        let token = localStorage.getItem("token");
        const lang = localStorage.getItem('lang') || 'en'
        // if(!maintoken) {
        //   this.props.history.push("/landing")
        // }
        return (
            <div style={{
                backgroundImage:"url(assets/images/website-background.png)",
                 backgroundColor: "#8A2432"
            }}>
                <UseTrackEvent location={window.location} setAddEvent={this.handleSetAddEvent} />
                
                <div className="main container" >
                    <Helmet>
                        <link rel="canonical" href={canonicalUrl} />
                    </Helmet>
                    <div className='row'>
                        <div className='landing-outer'>
                            <div className='col-lg-6 col-12 find-store-banner '>

                                <div className="">
                                    <h3 className='banner-content'><span style={{ color: "white" }}>{i18next.t("landingPage.fastest_text")} </span><span style={{ color: "rgba(255, 131, 0, 1)" }}>{i18next.t("landingPage.delivery_text")}</span> <br /> <span style={{ color: "white" }}> &</span>  <span style={{ color: "white" }}> {i18next.t("landingPage.easy_text")}</span><span style={{ color: "#8E634A" }}> {i18next.t("landingPage.pickup_text")}</span></h3>
                                    <p className='banner-content' style={{ fontSize: "25px", color: "white" }}>{i18next.t("landingPage.order_question")}</p>
                                    <div className="row d-flex justify-content-between">
                                        <div className="col-lg-6 col-6   w-100 ">
                                            <button
                                                className=" btn-outer-left"
                                                onClick={() => this.handleSelectChannel("Delivery")}
                                                style={{ fontSize: '18px' }}

                                            >{i18next.t('checkoutPage.order_method_delivery')}</button>
                                        </div>
                                        {/* <div className="col-lg-1 col-1 button-group w-100">
                                            <button
                                                className="or-text">or</button>
                                        </div> */}
                                        <div className="col-lg-6 col-6  w-100">
                                            <button
                                                className=" btn-outer-right"
                                                onClick={() => this.handleSelectChannel("Pickup")}
                                                style={{ fontSize: '18px' }}
                                            >{i18next.t('checkoutPage.order_method_pickup')}</button>
                                        </div>
                                    </div>
                                    {this.renderContent()}
                                    {/* <div className="location-form">
                            <div className="cart-table-container">
                                <div className="iwant-sec">
                            </div>
                            
                        </div>
                    </div> */}
                                </div>
                            </div>

                            <div className=" mb-4 col-lg-6 col-12 landing-banner-image " style={{ position: this.state.windowWidth > 988 ? "relative" : "unset", left: lang=='en'? "8%" : "-10%" }}>
                                {this.state.windowWidth > 988 ?
                                    <video className='ml-4 pt-4' style={{ width: "75%", }} muted playsInline autoPlay loop>
                                        <source src='assets/video/banner.mp4' type="video/mp4" />
                                    </video>
                                    :
                                    <video className='mb-4 mt-2 landing-banner-image' muted playsInline autoPlay loop>
                                        <source src='assets/video/banner.mp4' type="video/mp4" />
                                    </video>
                                    // <img className="mb-4 mt-2 landing-banner-image" title="" src="assets/images/KV IMAGE (2).png" alt="add Icon"
                                    // />
                                }


                                {/* <img className="mb-4 landing-banner-image" title="" src="assets/images/Frame 4576.png" alt="add Icon"
                                /> */}

                            </div>


                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        groupList: state.login.groupList,
        groupsHome: state.login.groupsHome,
        stores: state.login.storesList,
        cart: state.login.cartData,
        selectStoreId: state.login.selectStoreId,
        channel: state.login.channel,
        groupsData: state.login.groupsData,
        address_save: state.login.address_save,
        pickupStoresList: state.login.pickupStoresList,
        addressData: state.login.addressData,
        storeSelected: state.login.storeSelected,
        coordinates: state.login.coords,
        orderType: state.login.orderType,
        coupon: state.login.coupon,
        kml_shape:state.login.kml_shape
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        findStoreforLSM: function (lat: any, long: any, channel: any) {
            dispatch(findStoreforLSM(lat, long, channel));
        },
        menuGroupsListHome: (store_id: any, channel: any) => {
            dispatch(menuGroupsListHome(store_id, channel))
        },
        menuGroupsList: (store_id: any, channel: any) => {
            dispatch(menuGroupsList(store_id, channel))
        },
        TopDealsHome: function (store_id: any, channel: any) {
            dispatch(TopDealsHome(store_id));
        },
        TopList: function (store_id: any, channel: any) {
            dispatch(TopList(store_id));
        },
        TopItemsList: function (store_id: any, channel: any) {
            dispatch(TopItemsList(store_id));
        },
        selectMenuForPickup: (store: any, data: any) => {
            dispatch(selectMenuForPickup(store, data))
        },
        saveSelectStoreId: (store: any) => {
            dispatch(saveSelectStoreId(store));
        },
        addAddress: (data: any) => {
            dispatch(addAddressUser(data));
        },
        findPickupStores: function (lat: any, long: any, value: any) {
            dispatch(findPickupStores(lat, long, value));
        },
        saveGuestAddress: function (address: any) {
            dispatch(saveGuestAddress(address));
        },
        saveStore: (store: any) => {
            dispatch(saveStore(store));
        },
        addressesListk: function () {
            dispatch(addressesListk());
        },
        PickupStoresList: (lat: any, lng: any, searchString: any) => {
            dispatch(PickupStoresList(lat, lng, searchString))
        },
        saveOrderType: function (type: any) {
            dispatch(saveOrderType(type));
        },
        getCart: function () {
            dispatch(getCart());
        },
        saveCart: function (cart: any[]) {
            dispatch(saveCart(cart));
        },
        resetCoupon: function (obj: any) {
            dispatch(resetCoupon(obj))
        },
        saveAddressExtraDetails: (data: any) => {
            dispatch(saveAddressExtraDetails(data))
        },
        findLocalStores: (lat: any, lng: any) => {
            dispatch(findLocalStores(lat, lng))
        },
        PickupStoreListByDistance:(data:any)=>{
            dispatch(PickupStoreListByDistance(data))
        },
        getKmlShape:(lat:any,lng:any)=>{
            dispatch(getKmlShape(lat,lng))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(locationlanding);