import React from "react";
import { Component } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import brandLogo from "./public/assets/images/about-us-galadali.png"
import './aboutUs.css';

class index extends Component {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        console.log("")
        const script = document.createElement("script");

        script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
        script.async = true;

        document.body.appendChild(script);

    }
    render() {
        let lang = localStorage.getItem('lang')
        return (
            <main className="main">
                {/* <div style={{ position: "relative", zIndex: 2 }} className="container">
                    <div className="backBtn-wrapper mobile-view-page">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                <span className="iconify"
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                        color: "rgba(152, 162, 179, 1)"
                                    }}
                                    data-icon="bi:arrow-left-circle">
                                </span>
                            </div>
                        </Link>
                    </div>
                </div>
                {lang == 'en' ? <div className="about-section about-section-custom">
                    <div className="container">
                        <h2 className="subtitle text-center" style={{ fontSize: "2.4rem" }}>{i18next.t('ourStory.our_story')}</h2>
                        <div className="row">
                            <div className="col-lg-12" style={{
                                fontFamily: "Open Sans",
                                fontSize: "18px"
                            }}>
                                <p><strong>Since 1991 KyoChon has been finding and feeding the tastemakers of the world with what they know as the best <b style={{ color: "#FF8300" }}> Authentic Korean fried chicken</b>.</strong></p>
                                <div className='row mb-1'>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <p><strong>
                                            From Gumi City in South Korean, KyoChon has grown to become the leader in Korean fried chicken, operating across 8 countries including <b style={{ color: "#FF8300" }}>"Singapore, U.S, Malaysia, Indonesia, China, Thailand, Philippines"</b> and now the <b style={{ color: "#FF8300" }}>United Arab Emirates</b>.
                                        </strong></p>
                                        <p><strong> Dubbed as Korea’s most loved fried chicken, and for good reason… KyoChon has spent the last 30 years perfecting each recipe and developing a unique range of mouth-watering signature sauces.</strong></p>

                                    </div>
                                    <div style={{ alignSelf: 'center' }} className='col-sm-12 col-md-4 col-lg-4'>
                                        <img className='about-img image-responsive' src='/assets/images/Brushing-compress.jpg' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <blockquote><p style={{ margin: "0 0 0 0" }} className="quotation-mark">
                                            “
                                        </p><p><strong><i>Foodies across the UAE can now indulge on what we like to call <b style={{ color: "#FF8300" }}>‘That KyoChon crunch’</b>. Each piece of fresh chicken is cooked to order in low saturated fat Canola oil, giving you perfect, crispy skin and juicy, tender meat.</i></strong></p>
                                            <p className="quotation-mark">
                                                ”
                                            </p></blockquote>
                                    </div>
                                    <div style={{ alignSelf: 'center' }} className="col-sm-12 col-md-4 col-lg-4">
                                        <img className='about-img image-responsive' src='/assets/images/KV IMAGE-compress.png' />
                                    </div>
                                </div>

                                <p><strong>
                                    Every piece is then brushed with <b style={{ color: "#FF8300" }}> KyoChon signature sauces</b>. Made from the finest locally sourced Korean ingredients, you will have the choice of original soy garlic, red fiery fusion for spicy lovers & bittersweet Acacia Honey.</strong></p>
                                <p><strong>
                                    Rich in flavour with every bite, you can’t help but to savour the taste… making KyoChon chicken <b style={{ color: "#FF8300" }}>‘Too indulgent to rush’</b>.
                                </strong></p>
                                <p className="mt-1"><strong>
                                    <b style={{ color: "#FF8300" }}> Galadari Food & Beverage division</b>, a subsidiary of the Galadari
                                    Brothers Group, is the master franchisee for KyoChon in the Middle
                                    East and North Africa region. Ranked as one of the largest and most established business
                                    conglomerates in the UAE, the Galadari Brothers empire is
                                    the epicentre of a range of operations spread across a wide
                                    spectrum of industries, including construction, real estate,
                                    hospitality, construction chemicals and materials, heavy
                                    equipment, automotive, retail, publishing, and printing among
                                    others.
                                </strong></p>
                                <p className="mt-1"><strong>
                                    The Group is driven by ambition, values and boundless
                                    experience, drawing inspiration from the majestic panorama
                                    offered by Dubai’s skyline to engage in new ventures and
                                    maintain its position as a significant market player
                                    that channels over 30 world-renowned brands to the region,
                                    bringing on board over 6000 motivated employees from 30
                                    different nationalities.
                                </strong></p>
                             
                            </div>
                        </div>
                    </div>
                </div> : <div className="about-section">
                    <div className="container">
                        <h2 className="subtitle text-center" style={{ fontSize: "2.4rem" }}>{i18next.t('ourStory.our_story')}</h2>
                        <div className="row">
                            <div className="col-lg-12" style={{ fontFamily: "Open Sans", fontSize: "18px" }}>
                                <p><strong>‌منذ‌ ‌عام‌ 1991‌، ‌‍‌يعمل‌ ‌كيوتشون‌ ‌على‌ ‌إيجاد‌ ‌وتقديم‌ ‌الوجبات‌ ‌للذواقة‌ ‌حول‌ ‌العالم‌ ‌بوجبات‌ ‌يعرفونها‌ ‌جيدا‌‍‌ً .... ‌‍‌الدجاج‌ ‌المقلي‌ ‌الكوري‌ ‌الأصيل‌.</strong></p>
                                <div className='row mb-2'>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <p><strong>
                                            من‌ ‌مدينة‌ ‌غومي‌ ‌في‌ ‌كوريا‌ ‌الجنوبية‌‍‌، ‌‍‌نما‌ ‌كيوتشون‌ ‌ليصبح‌ ‌رائدا‌‍‌ً ‌‍‌في‌ ‌الدجاج‌ ‌المقلي‌ ‌الكوري‌ ‌ويعمل‌ ‌في‌ 8 ‌دول‌ ‌بما‌ ‌في‌ ‌ذلك‌ ‌سنغافورة‌
                                            ‌والولايات‌ ‌المتحدة‌ ‌وماليزيا‌ ‌وإندونيسيا‌ ‌والصين‌ ‌وتايلاند‌ ‌والفلبين‌ ‌والآن‌ ‌الإمارات‌ ‌العربية‌ ‌المتحدة‌.</strong></p>
                                        <p><strong> يطلق‌ ‌عليه‌ ‌اسم‌ ‌الدجاج‌ ‌المقلي‌ ‌الأكثر‌ ‌شهرة‌ ‌في‌ ‌كوريا‌‍‌، ‌‍‌وهذا‌ ‌بديهي‌  ... ‌فقد‌ ‌أمضى‌ ‌كيوتشون‌ ‌السنوات‌ ‌الثلاثين‌ ‌الماضية‌ ‌في‌ ‌إتقان‌ ‌كل‌ ‌وصفة‌
                                            ‌إلى‌ ‌جانب‌ ‌تطوير‌ ‌مجموعة‌ ‌فريدة‌ ‌من‌ ‌الصلصات‌ ‌الشهية‌.</strong></p>

                                    </div>
                                    <div style={{ alignSelf: 'center' }} className='col-sm-12 col-md-4 col-lg-4'>
                                        <img className='about-img image-responsive' src='/assets/images/Brushing-compress.jpg' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-sm-12 col-md-8 col-lg-8'>
                                        <blockquote><p style={{ margin: "0 0 0 0" }} className="quotation-mark">
                                            “
                                        </p><p><strong><i>يمكن‌ ‌لعشاق‌ ‌الطعام‌ ‌في‌ ‌جميع‌ ‌أنحاء‌ ‌الإمارات‌ ‌الآن‌ ‌الاستمتاع‌ ‌بما‌ ‌نود‌ ‌أن‌ ‌نطلق‌ ‌عليه‌ " ‌كيوتشون‌ ‌كرانش‌". ‌حيث‌ ‌يتم‌ ‌طهي‌ ‌كل‌ ‌قطعة‌ ‌دجاج‌
                                            ‌طازجة‌ ‌حسب‌ ‌الطلب‌ ‌بزيت‌ ‌الكانولا‌ ‌منخفض‌ ‌الدهون‌ ‌المشبعة‌‍‌، ‌‍‌مما‌ ‌يمنحك‌ ‌طبقة‌ ‌مثالية‌ ‌ومقرمشة‌ ‌ولحما‌‍‌ً ‌‍‌طريا‌‍‌ً.‌</i></strong></p>
                                            <p className="quotation-mark">
                                                ”
                                            </p></blockquote>
                                    </div>
                                    <div style={{ alignSelf: 'center' }} className="col-sm-12 col-md-4 col-lg-4">
                                        <img className='about-img image-responsive' src='/assets/images/KV IMAGE-compress.png' />
                                    </div>
                                </div>

                                <p><strong>
                                    ثم‌ ‌يتم‌ ‌دهن‌ ‌كل‌ ‌قطعة‌ ‌بصلصات‌ ‌كيوتشون‌ ‌المميزة‌ ‌المصنوعة‌ ‌من‌ ‌أجود‌ ‌المكونات‌ ‌التي‌ ‌نأتي‌ ‌بها‌ ‌من‌ ‌مصادرها‌ ‌الأصلية‌ ‌في‌ ‌كوريا‌‍‌، ‌‍‌سيكون‌
                                    ‌لديك‌ ‌خيار‌ ‌ثوم‌ ‌الصويا‌ ‌الأصلي‌‍‌، ‌‍‌مزيج‌ ‌السبايسي‌ ‌ريد‌ ‌لعشاق‌ ‌التوابل‌ ‌وعسل‌ ‌أكاسيا‌ ‌الحلو‌ ‌والمر‌.</strong></p>
                                <p><strong>
                                    ‌غني‌ ‌بالنكهة‌ ‌مع‌ ‌كل‌ ‌قضمة‌‍‌، ‌‍‌لا‌ ‌يسعك‌ ‌إلا‌ ‌أن‌ ‌تختبر‌ ‌هذا‌ ‌الطعم‌ ‌المميز‌ ... ‌مما‌ ‌يجعل‌ ‌دجاج‌ ‌كيوتشون‌ "‌طعم‌ ‌رائع‌ ‌لا‌ ‌يقاوم‌".</strong></p>
                                    <br></br>
                                <p><strong>
                                    ‌غني‌ ‌بالنكهة‌ ‌مع‌ ‌كل‌ ‌قضمة‌‍‌، ‌‍‌لا‌ ‌يسعك‌ ‌إلا‌ ‌أن‌ ‌تختبر‌ ‌هذا‌ ‌الطعم‌ ‌المميز‌ ... ‌مما‌ ‌يجعل‌ ‌دجاج‌ ‌كيوتشون‌ "‌طعم‌ ‌رائع‌ ‌لا‌ ‌يقاوم‌".</strong></p>
                                <p><strong>
                                يعتبر قسم كلداري للأغذية والمشروبات، التابع لمجموعة كلداري براذرز، صاحب الامتياز الرئيسي لشركة كيوتشون في منطقة الشرق الأوسط وشمال إفريقيا، وقد تم تصنيف إمبراطورية كلداري براذرز كواحدة من أكبر وأقوى تكتلات الأعمال في الإمارات العربية المتحدة، وكمركز لمجموعة من العمليات المنتشرة عبر مجموعة واسعة من الصناعات، بما في ذلك البناء والعقارات والضيافة والمواد الكيميائية ومواد البناء والمعدات الثقيلة والسيارات والبيع بالتجزئة والطباعة والنشر وعدة أمور اخرى. </strong></p>

                                <p className="mt-1"><strong>
                                مجموعتنا مدفوعة بالطموح والقيم والخبرة اللامحدودة، مستوحاة من البانوراما المهيبة التي يوفرها أفق دبي للإنخراط في مشاريع جديدة والحفاظ على مكانتها كلاعب مهم في سوق الأعمال الذي ينقل أكثر من 30 علامة تجارية مشهورة عالمياً إلى المنطقة، وذلك مع أكثر من 6000 موظف من 30 جنسية مختلفة.
                                </strong></p>
                            </div>
                        </div>
                    </div>
                </div>} */}
                <div className="container">
                    <div className="backBtn-wrapper mobile-view-page">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                <span className="iconify"
                                    style={{
                                        width: "45px",
                                        height: "45px",
                                        color: "rgba(152, 162, 179, 1)"
                                    }}
                                    data-icon="bi:arrow-left-circle">
                                </span>
                            </div>
                        </Link>
                    </div>
                    <h2 className="kc-about-title">KOREA’S MOST LOVED FRIED CHICKEN, BROUGHT TO YOU BY GALADARI BROTHERS GROUP</h2>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="qc-content">
                                <h5>About Galadari Brothers </h5>
                                <p>Galadari Brothers Group has been a pioneer in the Gulf region, particularly in the UAE,
                                    for over half a century. With a rich legacy of over five decades,
                                    the group has spearheaded numerous landmark businesses,
                                    demonstrating an unwavering commitment to excellence.</p>
                                <p>Galadari Brothers Group has been instrumental in introducing
                                    world-renowned brands and products to the UAE. It was the first
                                    to bring Baskin Robbins, an international ice cream brand, to the region,
                                    and launch the first English newspaper, Khaleej Times. The group also
                                    secured the Mazda dealership to introduce the first Japanese automobile
                                    brand in the UAE and opened the first 5-Star Hotel in the Gulf,
                                    Intercontinental Hotel. The group's business acumen and tenacity
                                    are further evidenced by securing the dealership of Komatsu, one
                                    of the most prominent heavy equipment brands.</p>
                                <p>The group's impact on the business landscape continues to be felt in UAE and beyond,
                                    and its legacy serves as an inspiration for generations to come.</p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src='/assets/images/about-us-galadali.png' className="qc-logo" alt="" />
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6 ky-order">
                            <img src='/assets/images/inogration-img.jpg' className="w-100" alt="" />
                        </div>
                        <div className="col-md-6">
                            <div className="qc-content pl-md-5">
                                <h5>About Galadari F&B Division</h5>
                                <p>Kyochon has been introduced to UAE by the Galadari
                                    Food & Beverage Division, a company with strong
                                    history of launching successful brands like Baskin
                                    Robbins in the Middle East, Dunkin’ in Oman and
                                    Qatar, among others.</p>
                                <p>Mohammed Galadari, Co-chairman & Group CEO of Galadari Brothers Group,
                                    explained that the Galadari Food and Beverage Division has strengthen
                                    its resolve to continue to bring new brands into the market that
                                    are in line with what customers are looking for.
                                    “There is a special moment when people sit down together
                                    for a delicious meal at their favourite restaurant and we
                                    are dedicated to making more of these special moments happen
                                    with the launch of KyoChon here in Dubai.”</p>
                            </div>
                        </div>

                    </div>
                    <div className="row mt-5">
                        <div className="col-md-6">
                            <div className="qc-content">
                                <h5>About Kyochon</h5>
                                <p>Since 1991, KyoChon has been finding and feeding the
                                    tastemakers of the world with what they know as the
                                    best Authentic Korean fried chicken. Popularly known as
                                    “Korea’s Most Loved Fried Chicken”, Kyochon has
                                    become a leader in Korean fried chicken across South
                                    Korea, Singapore, U.S, Malaysia, Indonesia, China,
                                    Thailand, Philippines" and now introduced in the United
                                    Arab Emirates by Galadari F&B Division. KyoChon has
                                    spent the last 30 years perfecting each recipe and
                                    developing a unique range of mouth-watering signature
                                    sauces.</p>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <img src='/assets/images/kyochon-designsmith.jpg' className="" alt="" />
                        </div>
                    </div>
                    <div className="ky-ceo-about">
                        <div className="ky-ceo w-100">
                            <h2>THREE PROMISES</h2>
                            <h5>세가지 약속</h5>
                        </div>
                        <div className="ky-promis mt-3">
                            <div className="promis-title">
                                <img src='/assets/images/icon-promise-1.svg' className="m-auto" alt="" />
                                <h5>We use only the finest ingredients.</h5>
                            </div>
                            <div className="promis-title">
                                <img src='/assets/images/icon-promise-2.svg' className="m-auto" alt="" />
                                <h5>We encourage and support the healthy lifestyle of our customers.</h5>
                            </div>
                            <div className="promis-title">
                                <img src='/assets/images/icon-promise-3.svg' className="m-auto" alt="" />
                                <h5>We pursue perfection in every fried chicken we make.</h5>
                            </div>
                        </div>
                        <div>
                            <ol className="steps">
                                <li className="step is-active" data-step="1">

                                </li>
                                <li className="step" data-step="2">

                                </li>
                                <li className="step" data-step="3">

                                </li>
                            </ol>
                        </div>
                        <div>
                            <img src='/assets/images/kyochon-ceo.png' className="ceo-img" alt="" />
                        </div>
                    </div>
                    <div>
                        <h2 className="find-title text-center">FIND A KYOCHON OUTLET NEAR YOU</h2>
                        <div className="d-flex justify-content-between flex-wrap mt-3 mb-5">
                            <h4>Burjuman Mall </h4>
                            <h4>Deira City Center</h4>
                            <h4>Mall of Emirates</h4>
                            <h4>First Avenue Mall</h4>
                            <h4>Al Diyafah</h4>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default index;
