import React, { useEffect, lazy, Suspense } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./Redux/store";
import TagManager from 'react-gtm-module'

import Main from "./components/Home/mainLogin";
// import Login from "./screens/Login";
// import SignUp from "./screens/SignUp";
// import CheckoutPage from "./screens/Checkout"
// import AccountAuth from './screens/AccountAuth'
import Location from "./screens/Checkout"
import Payment from "./screens/Payment"
import Profile from "./screens/Profile";
import NewForgot from "./screens/OtpPassword";
// import RestuarantLoc from "./screens/RestuarantLocator";
import TrackOrder from "./screens/TrackOrder";
import CustomerComplaint from "./screens/CustomerComplaint";
// import Aboutus from "./screens/Aboutus";
import Terms from "./screens/Terms"
import MailingList from './screens/NewsLetterMails'
import confirmLocation from "./components/Checkout/confirmLocation";
import HomeLogin from "./components/HomeLogin/HomeLogin";
import Aboutus from "./screens/Aboutus";
import AccountAuth from "./screens/AccountAuth";
import Cart from "./screens/Cart";
import RestuarantLocator from "./screens/RestuarantLocator";
import Landing from "./screens/Landing";
import NutritionalInfo from "./screens/NutritionalInfo";
import page404 from "./screens/page404";
import CookiePolicy from "./screens/Cookie";
import UseTrackEvent from "./hooks/useTrackHook";
import CookieConsent from "./components/CookieConsent";
const Home = lazy(() => import('./screens/Home'));
const tagManagerArgs = {
  gtmId: 'GTM-NQZ7ZPV2'
}
function App() {
  let location = useLocation()
  let windowObj = (window as any)
  windowObj.dataLayer.push({
      event: 'pageview',
      url:location
    });
useEffect(() => {
  TagManager.initialize(tagManagerArgs)
}, [location])

  return (
    <Provider store={store}>
      <UseTrackEvent location={location} setAddEvent={()=>{}} />
      <CookieConsent/>
      <Suspense fallback={<div></div>}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/menu" component={Home} />
          <Route exact path="/menu" render={() => (
            <Redirect to="/menu" />
          )} />
          <Route exact path="/cart" component={Cart} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/checkout/contact" component={AccountAuth} />
          <Route exact path="/checkout/location" component={Location} />
          <Route exact path="/checkout/payment" component={Payment} />
          <Route exact path="/locations-overview" component={RestuarantLocator} />
          <Route exact path="/track-order" component={TrackOrder} />
          <Route exact path="/feedback" component={CustomerComplaint} />
          <Route exact path="/reset-password" component={NewForgot} />
          <Route exact path="/our-story" component={Aboutus} />
          <Route exact path="/privacy-policy" component={Terms} />
          <Route exact path="/cookie-policy" component={CookiePolicy} />
          <Route exact path="/mailing-list" component={MailingList} />
          <Route exact path="/nutritional-info" component={NutritionalInfo} />
          <Route path="*" exact component={page404} />
        </Switch>
      </Suspense>
    </Provider>
  );
}

export default App;
