import React from 'react'
import Timer from 'react-compound-timer'
export const ReactTimer = (props:any) => {
    console.log("received code status on reactimer component", props.code)
    return (
        <Timer
            initialTime={30000}
            direction="backward"
        >
            {() => (
                <React.Fragment>
                   <strong className="text-bold" style={{ fontSize:"1.5em", paddingLeft: '10px'}}> <Timer.Seconds /> s </strong>
                </React.Fragment>
            )}
        </Timer>
    )
}