import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import CheckoutComponent from "../components/Checkout/confirmLocation";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

class Checkout extends Component<{history?:any,location?:any},{}> {
  constructor(props: any) {
    super(props);
  }
  componentDidMount(){
    // document.title = "Checkout - Tortilla";
    document.title = "Checkout - Kyochon";
  }
  render() {
    // if (!localStorage.mainToken) {
    //   return <Redirect to="/" />
    // }
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history} />
          <CheckoutComponent history={this.props.history} location={this.props.location} />
          <Footer />
        </div>
        
      </div>
    );
  }
}
export default connect(null, null)(Checkout);
