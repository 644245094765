import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import Location from "../components/Landing/index";

import { Redirect } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

class LandingPage extends Component<{ history: any }, {}> {
  componentDidMount() {
     // Get the current location
  const location = window.location.pathname;
  // Push the 'screen_view_home' event to the dataLayer
  let windowObj = window as any;
  windowObj.dataLayer.push({ ecommerce: null });
  windowObj.dataLayer.push({
    event: 'Screenview_home',
    page: location,
  });
    // document.title = "Kyochon - Korea's Most Loved Fried Chicken Now in UAE"
  }
  render() {
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            {/* <title>Kyochon - Korea's Most Loved Fried Chicken Now in UAE</title> */}
            <title>Kyochon: Best Korean Fried Chicken</title>
            {/* <meta name="description" content="Welcome to Kyochon ME - Order your favourite meals now. Try Crispy Fried Chicken with Signature sauces along with a range of sides and Korean desserts" /> */}
            <meta name="description" content="Discover the best Korean fried chicken by Kyochon, now in the UAE" />
            <meta name="tags" content="" />
          </Helmet>
          <div className="page-wrapper">
            <div id="page-wrap">
              <Header history={this.props.history} />
              <Location history={this.props.history} />
              <Footer />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default LandingPage;
