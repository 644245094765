import React, { Component } from "react";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import AboutUsComp from "../components/AboutUs/index";
import { Redirect } from "react-router-dom";

class Aboutus extends Component<{ history: any }, {}> {
  componentDidMount() {
    // document.title = "Our Story - Tortilla";
    document.title = "About Us - Kyochon";
  }
  render() {
    // if (!localStorage.mainToken) {
    //   return <Redirect to="/" />
    // }
    return (
      <div className="page-wrapper">
        <div id="page-wrap">
          <Header history={this.props.history} />
          <AboutUsComp />
          <Footer />
        </div>
      </div>
    );
  }
}

export default Aboutus;
