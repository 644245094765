import React from 'react';
import { withGoogleMap, GoogleMap, Marker, withScriptjs, KmlLayer, InfoWindow } from "react-google-maps";
import Geocode from "react-geocode";
import { GoogleMapsAPI } from '../../ApiManager/client-config';
Geocode.setApiKey(GoogleMapsAPI);
Geocode.enableDebug();

interface Props { }
interface State { [x: number]: any }
const google = (window as any).google;
var mapRef: any = null;
const RenderMap: any = withScriptjs ( withGoogleMap((props?: any) => (

    <>
        <GoogleMap
            ref={ref => { mapRef = ref; }}
            defaultZoom={props.zoom}
            onCenterChanged={() => { console.log() }}
            center={{ lat: props.location.lat, lng: props.location.lng }}
            defaultOptions= {{
                fullscreenControl: false,
                mapTypeControl: false
            }}
            // defaultCenter={{lat: props.defaultCenter.lat, lng: props.defaultCenter.lng}}
            onZoomChanged={props.onZoomChanged}         
            onDragEnd={props.onDragEnd}   
        >
            <img className="pinIcon" src="assets/images/pin.png" alt="pin" />
        </GoogleMap >
    </>
)));

class GoogleMaps extends React.Component<{ kml?: any, center?: any, google?:any, onSetData?: any, location?:any,address: any }, { currLoc: any, markersArr: any, lat: any, lng:any,address: any, city: any, area: any, state: any, country: any, country_code: any,currentZoom: any }> {
    _map: any
    constructor(props: any) {
        super(props);
        this.state = {
            address: '',
            city: '',
            area: '',
            state: '',
            country: '',
            country_code: '',
            currLoc: {},
            lat: 0,
            lng: 0,
            markersArr: [],
            currentZoom: 14
        }
    }

    componentDidMount() {
        if (this.props.address == "") {
          if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((position: any) => {
              this.setState({
                lat: position.coords.latitude,
                lng: position.coords.longitude
              });
              Geocode.fromLatLng(
                position.coords.latitude,
                position.coords.longitude
              ).then(
                (response: any) => {
                  const address = response.results[0].formatted_address,
                    addressArray = response.results[0].address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country_code = this.getCountryCode(addressArray),
                    country = this.getCountry(addressArray);
    
                  this.setState({
                    address: address ? address : "",
                    area: area ? area : "",
                    city: city ? city : "",
                    state: state ? state : "",
                    country: country ? country : "",
                    country_code: country_code ? country_code : ""
                  });
                  this.props.onSetData(this.state);
                },
                (error: any) => {
                  console.error(error);
                }
              );
            });
          }
        } else {
          Geocode.fromAddress(this.props.address).then(
            (response: any) => {
              const { lat, lng } = response.results[0].geometry.location;
              this.setState({ lat: lat, lng: lng,address:this.props.address});
              this.props.onSetData(this.state);
            },
            (error: any) => {
              console.error(error);
            }
          );
        }
      }


    shouldComponentUpdate(nextProps: any, nextState: any): boolean | any {
        if (
          this.state.lat !== nextState.lat ||
          this.state.address !== nextState.address ||
          this.state.city !== nextState.city ||
          this.state.area !== nextState.area ||
          nextProps.location.lat !== this.props.location.lat
        ) {
          if (nextProps.location.lat !== this.props.location.lat) {
            this.setState({ lat: nextProps.location.lat, lng: nextProps.location.lng })
            Geocode.fromLatLng(nextProps.location.lat, nextProps.location.lng).then(
              (response: any) => {
                const address = response.results[0].formatted_address,
                  addressArray = response.results[0].address_components,
                  city = this.getCity(addressArray),
                  area = this.getArea(addressArray),
                  state = this.getState(addressArray),
                  country_code = this.getCountryCode(addressArray),
                  country = this.getCountry(addressArray);
    
                this.setState({
                  address: (address) ? address : '',
                  area: (area) ? area : '',
                  city: (city) ? city : '',
                  state: (state) ? state : '',
                  country: (country) ? country : '',
                  country_code: (country_code) ? country_code : ''
                })
                this.props.onSetData(this.state);
              },
              (error: any) => {
                console.error(error);
              }
            );
          }
          return true
    
        }
        else {
          return false
        }
      }
    


        /**
     * Get the city and set the city input value to the one selected
     *
     * @param addressArray
     * @return {string}
     */
         getCity = (addressArray: any) => {
            let city = '';
            if (addressArray) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0] && 'administrative_area_level_2' === addressArray[i].types[0]) {
                        city = addressArray[i].long_name;
                        return city;
                    }
                }
            }
        };
        /**
         * Get the city and set the city input value to the one selected
         *
         * @param addressArray
         * @return {string}
         */
        getCountry = (addressArray: any) => {
            let country = '';
            if (addressArray) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                        country = addressArray[i].long_name;
                        return country;
                    }
                }
            }
        };
        /**
        * Get the city and set the city input value to the one selected
        *
        * @param addressArray
        * @return {string}
        */
        getCountryCode = (addressArray: any) => {
            let country_code = '';
            if (addressArray) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0] && 'country' === addressArray[i].types[0]) {
                        country_code = addressArray[i].short_name;
                        return country_code;
                    }
                }
            }
    
        };
        /**
         * Get the area and set the area input value to the one selected
         *
         * @param addressArray
         * @return {string}
         */
        getArea = (addressArray: any) => {
            let area = '';
            if (addressArray) {
                for (let i = 0; i < addressArray.length; i++) {
                    if (addressArray[i].types[0]) {
                        for (let j = 0; j < addressArray[i].types.length; j++) {
                            if ('sublocality_level_1' === addressArray[i].types[j] || 'locality' === addressArray[i].types[j]) {
                                area = addressArray[i].long_name;
                                return area;
                            }
                        }
                    }
                }
            }
        };
        /**
         * Get the address and set the address input value to the one selected
         *
         * @param addressArray
         * @return {string}
         */
        getState = (addressArray: any) => {
            let state = '';
            if (addressArray) {
                for (let i = 0; i < addressArray.length; i++) {
                    for (let i = 0; i < addressArray.length; i++) {
                        if (addressArray[i].types[0] && 'administrative_area_level_1' === addressArray[i].types[0]) {
                            state = addressArray[i].long_name;
                            return state;
                        }
                    }
                }
            }
        };
        /**
         * This Event triggers when the marker window is closed
         *
         * @param event
         */
        onInfoWindowClose = (event: any) => {
    
        };
    
        /**
         * When the marker is dragged you get the lat and long using the functions available from event object.
         * Use geocode to get the address, city, area and state from the lat and lng positions.
         * And then set those values in the state.
         *
         * @param event
         */
        onMarkerDragEnd = (event: any) => {
            let newLat = event.latLng.lat(),
                newLng = event.latLng.lng();
            Geocode.fromLatLng(newLat, newLng).then(
                (response: any) => {
                    const address = response.results[0].formatted_address,
                        addressArray = response.results[0].address_components,
                        city = this.getCity(addressArray),
                        area = this.getArea(addressArray),
                        state = this.getState(addressArray),
                        country_code = this.getCountryCode(addressArray),
                        country = this.getCountry(addressArray);
                    this.setState({
                        address: (address) ? address : '',
                        area: (area) ? area : '',
                        city: (city) ? city : '',
                        state: (state) ? state : '',
                        country: (country) ? country : '',
                        country_code: (country_code) ? country_code : '',
                        lat: newLat,
                        lng: newLng                    
                    })
                    this.props.onSetData(this.state);
                },
                (error: any) => {
                    console.error(error);
                }
            );
        };
    
        /**
         * When the user types an address in the search box
         * @param place
         */
        onPlaceSelected = (place: any) => {
            if (place.formatted_address) {
                const address = place.formatted_address,
                    addressArray = place.address_components,
                    city = this.getCity(addressArray),
                    area = this.getArea(addressArray),
                    state = this.getState(addressArray),
                    country = this.getCountry(addressArray),
                    country_code = this.getCountryCode(addressArray),
                    latValue = place.geometry.location.lat(),
                    lngValue = place.geometry.location.lng();
                // Set these values in the state.
                this.setState({
                    address: (address) ? address : '',
                    area: (area) ? area : '',
                    city: (city) ? city : '',
                    state: (state) ? state : '',
                    country: (country) ? country : '',
                    country_code: (country_code) ? country_code : '',
                    lat: latValue,
                    lng: lngValue
                })
                this.props.onSetData(this.state);
            }
        };

        handleBoundsChanged = () => {
            let mapCenter = mapRef.getCenter();
            let dupCenter = {
                lat: mapCenter.lat(),
                lng: mapCenter.lng()
            }
            Geocode.fromLatLng(dupCenter.lat, dupCenter.lng).then(
                (response: any) => {
                    const address = response.results[0].formatted_address,
                        addressArray = response.results[0].address_components,
                        city = this.getCity(addressArray),
                        area = this.getArea(addressArray),
                        state = this.getState(addressArray),
                        country_code = this.getCountryCode(addressArray),
                        country = this.getCountry(addressArray);
                    this.setState({
                        address: (address) ? address : '',
                        area: (area) ? area : '',
                        city: (city) ? city : '',
                        state: (state) ? state : '',
                        country: (country) ? country : '',
                        country_code: (country_code) ? country_code : '',
                        lat: dupCenter.lat,
                        lng: dupCenter.lng,
    
                    })
                    this.props.onSetData(this.state);
                },
                (error: any) => {
                    console.error(error);
                }
            );
        }

    handleMapLoad(map: any) {
        console.log(map.props.onZoomChanged())
        // this._map = map;
    }

    handleMapClick(event: any) {
        console.log(event);
    }

    handleZoomChanged = () => {
        let mapZoom = mapRef.getZoom();
        this.setState({currentZoom: mapZoom});
      }



    render() {
        return (
            <div style={{width:'100%'}}>
                <RenderMap
                    zoom={this.state.currentZoom}
                    location={this.props.location}
                    onZoomChanged={this.handleZoomChanged}
                    onSetData={this.props.onSetData}
                    // defaultCenter={this.state}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPI}&libraries=geometry,drawing,places`}
                    loadingElement={<div style={{ height: `300px`,width: '100%' }} />}
                    containerElement={<div style={{ height: `300px`, width: '100%' }} />}
                    mapElement={<div style={{ height: `300px`, width: '100%' }} />}
                    onMapLoad={this.handleMapLoad}
                    onDragEnd={this.handleBoundsChanged}
                // onMapClick={this.handleMapClick}
                />
            </div>
        )
    }
}

export default GoogleMaps;