/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { getStores, submitComplaint } from "../../Redux/Actions";
import { AddComplaintProps, AddComplaintStates } from "../Interface/loginInterface";
import './stylecomplaint.css';
import jwtDecode from "jwt-decode";
class AddComplaint extends Component<AddComplaintProps, AddComplaintStates>{

    constructor(props: any) {
        super(props);
        this.state = {
            fullname: "",
            email: "",
            feedbackType: "",
            receiptnumber: "",
            storeId: "",
            dateOfOrder: '',
            feedbackMessage: "",
            phone: '',
            enquiry_type: '',
            is_reply: null,
            netWork: false,
            isValidEmail: true,
            isValidPhone: true
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.validatePhone = this.validatePhone.bind(this);
    }
    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.getStores();
        const script = document.createElement("script");

        script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
        script.async = true;

        document.body.appendChild(script);
    }
    handleSubmit = (event: any) => {
        const { fullname, email, phone, feedbackMessage, feedbackType, storeId, dateOfOrder, receiptnumber, enquiry_type, is_reply } = this.state;
        this.setState({ netWork: true })
        const data: any = {
            name: fullname,
            phone_number: phone,
            receipt_number: receiptnumber,
            message: feedbackMessage,
            type: feedbackType,
            enquiry_type: enquiry_type,
            is_reply: is_reply
        };
        if (email !== "") {
            data.email_address = email
        }
        if (dateOfOrder !== '') {
            data.order_date = dateOfOrder
        }
        if (storeId !== '') {
            data.store_id = storeId;
        }
        setTimeout(() => {
            this.setState({ netWork: false })
        }, 3000);
        this.props.submitComplaint(data);
        // console.log(data)
    };
    handleInputChange(event: { target: { name: any; value: any } }) {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    blockInvalidChar = (e: any) =>
        ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

    validatePhone(event: { target: { name: any; value: any; }; }) {
        let reg = /^[0-9\b]+$/;
        if (event.target.value === '' || reg.test(event.target.value)) {
            this.setState({ phone: event.target.value, isValidPhone: true })
        } else {
            event.target.value = event.target.value.slice(0, event.target.value.length - 1);
            this.setState({ phone: event.target.value, isValidPhone: false });
        }
    };
    validateEmail = (event: { target: { name: any; value: any; }; }) => {
        let reg = /^\w+([\.-]?\w+)@\w+([\.-]?\w+)(\.\w{2,4})+$/;
        if (event.target.value != '') {
            if (reg.test(event.target.value)) {
                this.setState({ email: event.target.value, isValidEmail: true });
            } else {
                this.setState({ email: event.target.value, isValidEmail: false })
            }
        }
        else {
            this.setState({ email: event.target.value, isValidEmail: true })
        }
    };
    checkPhone = (phone: any) => {
        if (phone) {
            // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;
            // var mobileno = /^((\\+91-?)|0)?[0-9]{11}$/;
            var phoneno = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|53|54|55|56)[0-9]{7}$/;  // uae mobile numbers
            // if (phone.match(phoneno)) {
            //     return true;
            // } else {
            //     return false;
            // }
            if(phone.length < 12 || phone.length > 12){
                return false;
            } else {
                return true;
            }
        }
    };
    isUserReady = () => {
        let { fullname, email, phone, feedbackMessage, dateOfOrder, isValidEmail,storeId,isValidPhone } = this.state;
        let validateSignal = false;
        if (fullname == "" || (phone.length < 12 || phone.length > 12) || email == ''||isValidEmail==false || feedbackMessage=='') {
            validateSignal = false;
        } else {
            validateSignal = true
        }
        // if (phone.length < 10 || phone.length > 15) {
        //     validateSignal = false;
        // }
        // if(storeId !='') {
        //     validateSignal = true;
        // }
        // if (email !== '') {
        //     validateSignal = isValidEmail ? true : false;
        // }
        return validateSignal;
    }
    UNSAFE_componentWillReceiveProps(nextProps: any) {
        let token: any = localStorage.getItem("token");
        if (token) {
            let decodedData: any = jwtDecode(token);
            if (decodedData.customer.phone_number && decodedData.customer.phone_number !== '') {
                let str_phone_number = decodedData.customer.phone_number;
                this.setState({ phone: str_phone_number })
            }
            this.setState({
                fullname: decodedData.customer.login_name,
                email: decodedData.customer.email_address,
            });
        }
    }
    render() {
        let lang = localStorage.getItem("lang")
        let { fullname, email, phone } = this.state;
        const { stores } = this.props;
        let todayDateTime = new Date();
        let today = todayDateTime.toISOString().split("T")[0];
        return (
            <main className="main">
                <div style={{position:"relative",zIndex:2}} className="container">
                    <div className="backBtn-wrapper mobile-view-page-contactus">
                        <Link to={`/menu${window.location.search}`}>
                            <div className="org-header__btn--circle">
                                {/* <svg data-v-c2f068f0="" fill="#820c24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" data-spec="icon-arrow-left" className="org-header__icon" style={{ width: '15px', height: '15px' }}><path d="M74.363 79.637a8.997 8.997 0 010 12.727C72.605 94.121 70.303 95 68 95s-4.605-.879-6.363-2.637l-36-36a8.997 8.997 0 010-12.727l36-36a8.997 8.997 0 0112.727 0 8.997 8.997 0 010 12.727L44.727 50l29.636 29.637z"></path></svg> */}
                                <span className="iconify"
                                    style={{
                                        // marginRight: "10px",
                                        width: "45px",
                                        height: "45px",
                                        color: "rgba(152, 162, 179, 1)"
                                    }}
                                    data-icon="bi:arrow-left-circle">
                                </span>
                            </div>
                        </Link>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <div className="form-row" >
                                        <div className="form-group col-md-12">
                                            <h5 style={{ fontSize: "22px",textAlign:"center" }}>{i18next.t('feedbackFormPage.feedback_to_share')}</h5>
                                            {/* <strong className=" text-dark myprofile" style={{fontSize:"20px", marginBottom:"10px"}}>Well, you’ve come to the right place!</strong><br /> */}
                                            <p className="myprofile" style={{fontSize:"18px",marginTop:"11px"}}>{i18next.t('feedbackFormPage.will_be_touch_soon')} <span style={{ color: "#8a2432" }}>*</span> {i18next.t('feedbackFormPage.indicate')}</p>
                                        </div>
                                        <div className="col-12">
                                            <fieldset className="border p-2">
                                                <legend className="w-auto ml-4 pt-4 "><h4 className="myprofile" style={{ color: "#8a2432" }}>{i18next.t('feedbackFormPage.contact_details')}</h4></legend>
                                                <div className="row p-4">
                                                    <div className="form-group col-md-6">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt">{i18next.t('feedbackFormPage.full_name')} <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <input name="fullname"
                                                            placeholder={lang=="en"?"Full Name" :"الاسم الكامل"}
                                                            defaultValue={fullname}
                                                            style={{ fontSize: "15px" }}
                                                            type="text" onChange={this.handleInputChange}
                                                            className="form-control" id="round" required />
                                                    </div>
                                                    <div className="form-group col-md-6">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt">{i18next.t('feedbackFormPage.phone_number')} (971xxxxxxxxx) <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <input
                                                            name="phone"
                                                            autoComplete='off'
                                                            placeholder={lang=="en"?"Phone" : "رقم الهاتف"}
                                                            defaultValue={phone}
                                                            style={{ fontSize: "15px" }}
                                                            type="tel" onChange={this.validatePhone}
                                                            className="form-control" id="round" required />
                                                        {(!this.checkPhone(this.state.phone) && this.state.phone !== "") &&
                                                            <small style={{fontSize:'14px'}} className="form-text text-danger">{i18next.t('feedbackFormPage.invalid_phone_number')}</small>
                                                        }
                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt"> <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <input name="email"
                                                            placeholder={lang=="en"?"Email" : "البريد الإلكتروني"}
                                                            defaultValue={email}
                                                            style={{ fontSize: "15px" }}
                                                            type="email" onChange={this.validateEmail}
                                                            className="form-control" id="round" required />
                                                        {(this.state.isValidEmail == false && this.state.email !== "") &&
                                                            <span style={{fontSize:'14px'}} className="form-text text-danger">{i18next.t('errorMsg.invalid_email')}</span>}
                                                    </div>

                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="col-12">
                                            <fieldset className="border p-2">
                                                <legend className="w-auto ml-4 pt-4"><h4 className="myprofile"  style={{ color: "#8a2432" }}>{i18next.t('feedbackFormPage.about_your_visit')}</h4></legend>
                                                <div className="row p-4">
                                                    <div className="form-group col-md-12">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt">{i18next.t('feedbackFormPage.kyochon_store')}</h5>

                                                        <select onChange={this.handleInputChange} style={{ paddingLeft: '2rem' }} className="custom-select custom-select-lg mb-3 w-100" id="round1" name="storeId">
                                                            <option value="">{i18next.t('feedbackFormPage.select_store')}</option>

                                                            {stores &&
                                                                stores.map((store, index) => (
                                                                    <option key={index} value={store.store_id}>{lang=='ar' ? store.store_name_ar ? store.store_name_ar : store.store_name : store.store_name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>

                                                    {/* <div className="form-group col-md-6">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt">{i18next.t('feedbackFormPage.order_date')} </h5>
                                                        <input type="date" style={{ fontSize: "15px" }} max={today} name="dateOfOrder" required data-msg="Please Enter Order Date" className="form-control laterDateInput" onChange={this.handleInputChange} />
                                                    </div> */}
                                                    {/* <div className="form-group col-md-6">
                                                        <h5 id="txt">  Receipt Number <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <input name="receiptnumber" style={{ fontSize: "15px" }} type="number" min={1} placeholder="Enter receipt number" onKeyDown={this.blockInvalidChar} onChange={this.handleInputChange} className="form-control" id="round" />
                                                    </div> */}
                                                    {/* <div className="form-group col-md-6">
                                                        <h5 id="txt">TYPE OF FEEDBACK <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <select onChange={this.handleInputChange} style={{ paddingLeft: '2rem' }} className="custom-select custom-select-lg mb-3 w-100" id="round1" name="feedbackType">
                                                            <option value="">Select Feedback Type</option>
                                                            <option value="Compliment">Compliment</option>
                                                            <option value="Complaint">Complaint</option>
                                                            <option value="General Enquiry">General Enquiry</option>
                                                        </select>
                                                    </div> */}
                                                    {/* <div className="form-group col-md-6">
                                                        <h5 id="txt"> WHAT IS YOUR ENQUIRY ABOUT: <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <select onChange={this.handleInputChange} style={{ paddingLeft: '2rem' }} className="custom-select custom-select-lg mb-3 w-100" id="round1" name="enquiry_type">
                                                            <option value="">Select</option>
                                                            <option value="Service">Service</option>
                                                            <option value="Food">Food</option>
                                                            <option value="Online Order">Online Order</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div> */}
                                                    <div className="form-group col-md-12">
                                                        <h5 className=" text-dark myprofile" style={{fontSize:"16px"}} id="txt">{i18next.t('feedbackFormPage.comments')} <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <textarea name="feedbackMessage" id="round2" style={{ fontSize: '15px' }} rows={8} cols={120} onChange={this.handleInputChange}></textarea>
                                                        {/* <h5 className=" text-dark myprofile" style={{ color: 'rgb(100, 99, 99)',fontSize:"16px" }}><strong>Call us on 800 8678</strong></h5> */}
                                                    </div>
                                                    {/* <div className="form-group col-md-12">
                                                        <h5 id="txt">WOULD YOU LIKE US TO REPLY TO THIS FEEDBACK? <sup style={{ color: "#8a2432", fontSize: "12px" }}>*</sup></h5>
                                                        <div className="form-check" onClick={() => { this.setState({ is_reply: 1 }) }}>
                                                            <input className="form-check-input" checked={this.state.is_reply == 1 && true} type="checkbox" id="flexCheckDefault" />
                                                            <label className="mx-4" style={{ fontSize: 15 }}>
                                                                Yes please
                                                            </label>
                                                        </div>
                                                        <div className="form-check" onClick={() => { this.setState({ is_reply: 0 }) }}>
                                                            <input className="form-check-input" checked={this.state.is_reply == 0 && true} type="checkbox" id="flexCheckChecked" />
                                                            <label className="mx-4" style={{ fontSize: 15 }}>
                                                                No thanks
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="form-group col-md-4 p-3">
                                            {this.state.netWork == false ?
                                                <button
                                                    disabled={!this.isUserReady()}
                                                    onClick={this.handleSubmit} id="h"
                                                    type="submit" className="btn custom-default-red-button">
                                                    {i18next.t('feedbackFormPage.submit')}</button>
                                                :
                                                <button
                                                    disabled={this.state.netWork}
                                                    id="roundb"
                                                    className="btn custom-default-red-button"
                                                >
                                                    <i className="fa fa-spinner fa-spin"></i>{i18next.t('common.loading')}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
const mapStateToProps = (state: any) => {
    return {
        stores: state.login.restuarants,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        getStores: function (data: any) {
            dispatch(getStores(data));
        },
        submitComplaint: function (data: any) {
            dispatch(submitComplaint(data))
        }
        // signupmodalClose: function () {
        //   dispatch(signupmodalClose());
        // },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddComplaint);
