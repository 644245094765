import _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  logoutCustomer,
  menuGroupsList,
  menuItemsListByGroupsID,
  getCart,
  saveCart,
} from "../../Redux";
import { HeaderProps, HeaderState } from "../Interface/loginInterface";
import { Scrollbars } from "rc-scrollbars";
import { BASE_URL, priceunit } from "../../ApiManager/client-config";
import "./headerStyle.css";
import jwt_decode from "jwt-decode";
import i18next from "i18next";
import { findPickupStores, findStoreforLSM, findStoreforLSMWithoutMenuApiCall, lastOrder, menuGroupsListHome, menuHeroItems, PickupStoreListByDistance, PickupStoresList, saveGuestAddress, saveOrderType, saveSelectStoreId, saveStore, saveurlMethod, selectMenuForPickup, selectMenuForPickupWithoutMenuApiCall, setTabName, TopDealsHome, TopItemsList, TopList, updateLSM, UpdateMenuItems, getBrandDetails } from "../../Redux/Actions";
import { toast } from "react-toastify";
import { Modal } from "react-responsive-modal";
import { Button, Modal as BsModal } from 'react-bootstrap';
import GoogleMap from '../Landing/newMap';
import axios from "axios";
import Geocode from "react-geocode";
import { API_URL } from '../../ApiManager/client-config'
import UseTrackEvent from "../../hooks/useTrackHook";

class index extends Component<HeaderProps, HeaderState> {

  constructor(props: any) {

    super(props);
    this.state = {
      showSideNav: false,
      showMenu: false,
      infoOpen: false,
      isOpen: false,
      pickupStoreSelectFlag: true,
      showHide: false,
      placesSuggestion: [],
      filteredStores: [],
      removeableLsmItemsArr: [],
      searchString: "",
      position: {},
      lat: "",
      lng: "",
      selectedAddress: "",
      searchString2: "",
      cartShowModal: false,
      storeOpenFlag: true,
      use_pickup_map: false,
      directions: null,
      locationEnabled: false,
      origin: null,
      addEvent: () => { }
    };

  }
  async componentDidMount() {
    let route = window.location.pathname
    this.props.getCart();
    this.props.getBrandDetails()

    let token = localStorage.getItem("token")
    if (token) {
      this.props.lastorder()
    }
    let invalidItemFlag: any = sessionStorage.getItem('invalidItemFlag');
    if (invalidItemFlag == true) {
      this.ValidateItemForStore()
    }
    // this.props.PickupStoresList();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        console.log("current position call if", position)
        this.setState({ origin: { lat: position.coords.latitude, lng: position.coords.longitude, position: { lat: position.coords.latitude, lng: position.coords.longitude } } })
        let data1: any = {
          brand_id: 1,
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }
        this.props.PickupStoreListByDistance(data1)
      })
    } else {
      console.log("current position call else")
    }

    navigator.geolocation.getCurrentPosition(position => {
      this.setState({ locationEnabled: true })
      console.log(position)
    }, error => {
      this.props.PickupStoresList();
      this.setState({ use_pickup_map: false, locationEnabled: false })
      console.error(error)
    })


    let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
    let timeZoneOffset = -(timezone);
    let today = new Date();
    let todayDay = today.getDay();
    let data = {
      timeZoneOffset: timeZoneOffset,
      day: todayDay
    }
    this.props.getCart();
    const { groupList } = this.props;
    let changedLat: any = sessionStorage.getItem("changeLat");
    let changedLng: any = sessionStorage.getItem("changeLng");
    let orderType: any = sessionStorage.getItem("orderType");
    if (orderType) {
      this.props.saveOrderType(orderType);
    }
    if (orderType == "Delivery") {
      console.log("status check if case")
      if (changedLng != "" && changedLat != "" && changedLat != undefined && changedLng != undefined && changedLat != null && changedLng != null) {
        if (this.props.groupsData.length === 0) {
          if (route == '/menu') {
            await this.props.findStoreforLSM(changedLat, changedLng, data)
          }
          else {
            if (route != '/checkout/payment') {
              this.props.findStoreforLSMWithoutMenuApiCall(changedLat, changedLng, data)
            }
          }
        }
      }
      else {
        console.log("status check else case")
        if (route == '/menu') {
          this.props.menuGroupsListHome(null, data);
          this.props.menuGroupsList(null, data)
          this.props.TopDealsHome(null)
          this.props.TopList(null);
          this.props.TopItemsList(null);
          this.props.menuHeroItems(null);
        } else {
          if (route == '/cart') {
            this.props.TopDealsHome(null)
            this.props.TopList(null);
            this.props.TopItemsList(null);
          }
        }

      }
    } else {
      let selectedStore = sessionStorage.getItem("selectedStore");
      if (selectedStore) {
        if (route == '/menu') {
          this.props.selectMenuForPickup(JSON.parse(selectedStore), data);
        } else {
          this.props.selectMenuForPickupWithoutMenuApiCall(JSON.parse(selectedStore), data)
        }
        this.props.saveStore(JSON.parse(selectedStore))
      }
    }
    let selectedStore = sessionStorage.getItem("selectedStore");
    if (selectedStore) {
      this.props.saveStore(JSON.parse(selectedStore))
    }
    const script = document.createElement("script");

    script.src = "https://code.iconify.design/2/2.2.1/iconify.min.js";
    script.async = true;

    document.body.appendChild(script);
  }
  componentDidUpdate(prevProps: any, nextProps: any) {
    // let invalidItemFlag: any = sessionStorage.getItem('invalidItemFlag');
    if (prevProps.storeOpenFlag != this.props.storeOpenFlag && window.location.pathname === '/menu') {
      this.setState({ storeOpenFlag: this.props.storeOpenFlag });
      return true
    }
    if (prevProps.storeSelected && this.props.storeSelected && prevProps.storeSelected.store_id != this.props.storeSelected.store_id && this.props.orderType == 'Delivery' && window.location.pathname !== '/checkout/location') {
      if (this.props.cart.length > 0) {
        this.ValidateItemForStore()
      }
    }
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const store_id = urlParams.get('store_id');
    const mode = urlParams.get('md')
    if (nextProps.pickupStoresList !== this.props.pickupStoresList) {
      this.setState({ filteredStores: nextProps.pickupStoresList })
      if (store_id && mode) {
        let store = nextProps.pickupStoresList.find((element: any) => element.store_id == store_id)
        console.log("store found from pickupstoreslist and url params")
        this.props.saveStore(store)
        this.props.saveOrderType(mode)
        sessionStorage.setItem("orderType", mode);
        sessionStorage.setItem("selectedStore", store)
        this.confirmQrPickup(store)
      }
    }
    return true;
  }
  handleDelete = (item: any, index: any) => {
    let data: any = this.props.cart;
    let cart = JSON.parse(data);
    let products: any = []
    let MapObj: any = {}
    if (item.item) {
      MapObj = {
        quantity: item.quantity,
        price: item.price,
        id: item.item.menu_item_id,
        name: item.item.item_name,
        category: this.props.GroupName ? this.props.GroupName : ""
        //variant: cartItem.selectedsize.size
      }
    }
    else if (item.combo) {
      MapObj = {
        quantity: item.quantity,
        price: item.price,
        id: item.combo.combo_id,
        name: item.combo.combo_name,
        category: this.props.GroupName ? this.props.GroupName : ""
      }
    }
    products.push(MapObj)
    // let windowObj = (window as any);
    // windowObj.dataLayer.push({ ecommerce: null });
    // windowObj.dataLayer.push({
    //   'event': 'removeFromCart',
    //   'ecommerce': {
    //     'currencyCode': 'AED',
    //     'remove': {                               // 'remove' actionFieldObject measures.
    //       'products': products
    //     }
    //   }
    // });
    cart.splice(index, 1);
    this.props.saveCart(cart);
    this.props.getCart();
    // if (cart.length == 0) {
    //   this.props.history && this.props.history.push("/cart")
    // }
    if (this.props.history && this.props.history.location.pathname !== "/" && this.props.history.location.pathname !== "/menu") {
      if (cart.length == 0) {
        this.props.history && this.props.history.push({ pathname: "/cart", search: window.location.search })
      }
    }
  };

  saveEvents = (option: any) => {
    try {
      this.state.addEvent({
        category: "events",
        events: option
      });
    } catch (error) {
      console.error("error while saving event on header page", error);
    }
  }

  handleSetAddEvent = (eventFunc: any) => {
    this.setState({ addEvent: eventFunc });
  }
  //here
  goToCheckout = async () => {
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let products: any = []
    let MapObj: any = {}

    cart.forEach((cartItem: any) => {
      if (cartItem.item) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.item.menu_item_id,
          name: cartItem.item.item_name,
          variant: cartItem.selectedsize.size
        }
      }
      else if (cartItem.combo) {
        MapObj = {
          quantity: cartItem.quantity,
          price: cartItem.totalItemPrice,
          id: cartItem.combo.combo_id,
          name: cartItem.combo.combo_name
        }
      }
      products.push(MapObj)
    })

    // let windowObj = (window as any)
    // windowObj.dataLayer.push({ ecommerce: null });
    // windowObj.dataLayer.push({
    //   'event': 'checkout',
    //   'ecommerce': {
    //     'currencyCode': 'AED',
    //     'checkout': {
    //       'actionField': { 'step': 'Location', 'option': 'Cash' },
    //       'products': products
    //     }
    //   }
    // });
    this.saveEvents({ event_name: "click on checkout button" })
    const token = localStorage.getItem('token');
    if (token) {
      this.props.history.push({ pathname: '/checkout/location', search: window.location.search })
      this.props.setTabName("location")
    } else {
      this.props.history.push({ pathname: '/checkout/contact', search: window.location.search })
      this.props.setTabName("contact")
    }
  }



  renderCartButton = (data: any) => {
    const lang = localStorage.getItem('lang') || 'en'
    if (!_.isEmpty(data)) {
      let cart: any = JSON.parse(data);
      // console.log("cartSidebar", cart);
      let totalItemQuantity = 0;
      cart.map((item: any) => {
        totalItemQuantity += item.quantity;
      })
      if (cart.length > 0) {
        return (
          <div className="dropdown cart-dropdown cart-dropdown-hover">
            <Link
              to={`/cart${window.location.search}`}
              style={{ color: '#fff' }}
              className="dropdown-toggle dropdown-arrow"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-display="static"
            >
              <i className="fa fa-cart-plus" style={{ color: "white", marginTop: "unset" }}></i>

              {/* <i className="fa fa-shopping-basket"></i> */}
              {/* <img
                className="header-icon"
                src={process.env.PUBLIC_URL + "/assets/images/cart icon.svg"}
                alt="Kyochon Logo"
              /> */}
              <span className="cart-count badge-circle custom-default-green-button">{totalItemQuantity}</span>
            </Link>

            <div className="dropdown-menu">
              <div className="dropdownmenu-wrapper">
                <div className="dropdown-cart-header">
                  <span>{totalItemQuantity} {cart.length > 1 ? i18next.t('cartPage.Items') : i18next.t('cartPage.Item')}</span>

                  <Link to={`/cart${window.location.search}`} className="float-right">
                    <p style={{ color: "#FF8300" }}> {i18next.t('cartPage.view_cart')}</p>
                  </Link>
                </div>
                {/* <!-- End .dropdown-cart-header --> */}
                <Scrollbars style={{ height: 300 }}>
                  <div className="dropdown-cart-products">
                    {cart.map((carts: any, index: any) => (
                      <div className="product">
                        <div style={{ width: '70%' }} className="product-details">
                          <h4 className={lang == "ar" ? "product-title-arabic" : "product-title"}>
                            {!_.isEmpty(carts.item) &&
                              <a>
                                {carts.quantity}{'X. '}
                                {lang == 'ar' && carts.item.name_ar ? carts.item.name_ar : carts.item.item_name}
                              </a>
                            }
                            {!_.isEmpty(carts.combo) &&
                              <a>
                                {carts.quantity}{'X. '}
                                {carts.combo.combo_name}
                              </a>
                            }
                          </h4>

                          <span className="cart-product-info">
                            <span className="cart-product-qty">
                              {(carts && carts.length > 0) && priceunit + (carts.totalItemPrice).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        {/* <!-- End .product-details --> */}

                        <figure style={{ marginLeft: "auto" }}>
                          <a className="product-image">
                            <p
                              style={{ float: lang == 'ar' ? 'left' : "right" }}
                              onClick={() => {
                                this.handleDelete(carts, index);
                              }}><i className="fas fa-times"></i></p>
                            <img
                              // src="assets/images/c1.jpg"
                              src={`${BASE_URL}${carts.selectedsize ? carts.selectedsize.image_url : carts.image
                                }`}
                              alt={carts.selectedsize && carts.selectedsize.alt_text}
                              width="80"
                              height="40"
                            />
                          </a>
                        </figure>
                      </div>
                    ))}
                  </div>
                </Scrollbars>

                <div onClick={this.goToCheckout} className="dropdown-cart-action">
                  <button
                    className="btn btn-block btn-sm btn-primary custom-default-red-button">
                    {i18next.t('cartPage.checkout')}
                  </button>
                </div>
                {/* <!-- End .dropdown-cart-total --> */}
              </div>
              {/* <!-- End .dropdownmenu-wrapper --> */}
            </div>

            {/* <!-- End .dropdown-menu --> */}
          </div>
        );
      } else {
        return (
          <div className="dropdown ">
            <Link
              to={`/cart${window.location.search}`}
              className="dropdown-toggle"
              aria-haspopup="true"
              aria-expanded="false"
              data-display="static"
            >
              <i className="fa fa-cart-plus" style={{ color: "white", marginTop: "unset" }}></i>
              {/* <i className="fa fa-shopping-basket"></i> */}
              {/* <img
                className="header-icon"
                src={process.env.PUBLIC_URL + "/assets/images/cart icon.svg"}
                alt="Kyochon Logo"
              /> */}

            </Link>
          </div>
        );
      }
    } else {
      return (
        <div className="dropdown cart-dropdown ">
          <Link
            to={`/cart${window.location.search}`}
            className="dropdown-toggle"
            aria-haspopup="true"
            aria-expanded="false"
            data-display="static"
          >
            {/* <img
              className="header-icon"
              src={process.env.PUBLIC_URL + "/assets/images/cart icon.svg"}
              alt="Kyochon Logo"
            /> */}
            <i className="fa fa-cart-plus" style={{ color: "white", marginTop: "unset" }}></i>

            {/* <i className="fa fa-shopping-basket"></i> */}
          </Link>
        </div>
      );
    }
  };

  openMobileMenu = () => {
    let doc = document as any;
    let o;
    if (this.state.showMenu === false) {
      doc && doc.getElementsByTagName("html")[0] ? doc.getElementsByTagName("html")[0].style.overflow = 'hidden' : o = '';
      // (document as any).body.style.overflow = 'hidden';
    }
    else {
      doc && doc.getElementsByTagName("html")[0] ? doc.getElementsByTagName("html")[0].style.overflow = 'scroll' : o = '';
    }
    this.setState({ showMenu: !this.state.showMenu })
  }
  activeScroll = () => {
    this.setState({ showMenu: false })
    let doc = document as any;
    let o;
    doc && doc.getElementsByTagName("html")[0] ? doc.getElementsByTagName("html")[0].style.overflow = 'scroll' : o = '';
  }

  change = (option: any) => {
    localStorage.setItem('lang', option)
    window.location.reload()
  }

  backToLanding = () => {
    // let data: any = typeof this.props.cart === "string" ? JSON.parse(this.props.cart) : this.props.cart;
    // if (data.length > 0) {
    //   this.setState({ infoOpen: true })
    // }
    // else {
    //   this.props.history.push('/');
    // }
    this.setState({ isOpen: true });
    let doc = document as any;
    let o;
    doc && doc.getElementsByTagName("html")[0] ? doc.getElementsByTagName("html")[0].style.overflow = 'unset' : o = '';
  }

  onCloseModal = () => {
    this.setState({ infoOpen: false });
    let dataCart: any = [];
    this.props.saveCart(dataCart);
    this.props.getCart();
    this.props.history.push('/');
  };
  cartConfirmationCloseModal = () => {
    // this.setState({ infoOpen: false });
    this.cartCloseModal()
    let dataCart: any = [];
    this.props.saveCart(dataCart);
    this.props.getCart();
    sessionStorage.removeItem("cart")
    let { storeSelected } = this.props;
    let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
    let timeZoneOffset = -(timezone);
    let today = new Date();
    let todayDay = today.getDay();
    let data = {
      timeZoneOffset: timeZoneOffset,
      day: todayDay
    }
    setTimeout(() => {
 
      if (this.props.orderType == 'Delivery') {
       
          sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
          this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
          this.setState({ isOpen: false })
      }
      else {
        sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
        this.props.selectMenuForPickup(storeSelected, data);
        this.setState({ isOpen: false })
        this.ValidateItemForStore()
      }
    }, 5);
    // this.props.history.push('/');
  };

  closeModal = () => {
    this.setState({ isOpen: false })
    let doc = document as any
    let filter = this.state.filteredStores
    let v = ''
    for (let i = 0; i < filter.length; i++) {
      filter[i].is_clicked = false
      var deActiveItem = doc.getElementsByClassName("item")
      deActiveItem[i] && deActiveItem[i].classList ? deActiveItem[i].classList.remove("active-item1") : v = ''
    }
    this.setState({ filteredStores: filter, use_pickup_map: false, directions: null })

  }

  handleSelectChannel = (channel: any) => {
    this.props.saveOrderType(channel)
    let filter: any = this.state.filteredStores
    if (channel == 'Delivery') {
      for (let i = 0; i < filter.length; i++) {
        filter[i].is_clicked = false
      }
      this.setState({ filteredStores: filter, use_pickup_map: false, directions: null })
    }
    sessionStorage.setItem("orderType", channel);

  }

  confirmLocation = (e:any) => {
    if (this.props.orderType == 'Delivery') {
          let windowObj = (window as any)
          windowObj.dataLayer.push({desc: null });
          windowObj.dataLayer.push({
          event: 'confirm_location_clicked',
          desc:"Confirm Location"
          })
      this.confirmDelivery()
    }
    else {
      this.confirmPickup()
      this.ValidateItemForStore()
    }
  }
  confirmQrPickup = async (store: any) => {
    let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
    let timeZoneOffset = -(timezone);
    let today = new Date();
    let todayDay = today.getDay();
    let data = {
      timeZoneOffset: timeZoneOffset,
      day: todayDay
    }
    let cart = sessionStorage.getItem("cart") ? sessionStorage.getItem("cart") : null
    let storeSelected = sessionStorage.getItem("selectedStore") ? sessionStorage.getItem("selectedStore") : null
    if (cart && storeSelected) {
      this.setState({ isOpen: false })
      this.setState({ cartShowModal: true })
    }
    else {

      sessionStorage.setItem("selectedStore", JSON.stringify(store))
      this.props.selectMenuForPickup(store, data);
      this.props.saveStore(store)
      this.setState({ isOpen: false })
    }
    // this.props.history.push('/menu')
  }
  confirmPickup = async () => {
    let { storeSelected } = this.props;
    let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
    let timeZoneOffset = -(timezone);
    let today = new Date();
    let todayDay = today.getDay();
    let data = {
      timeZoneOffset: timeZoneOffset,
      day: todayDay
    }
    let cart = sessionStorage.getItem("cart") ? sessionStorage.getItem("cart") : null
    let storeSelectedd: any = sessionStorage.getItem("selectedStore") ? sessionStorage.getItem("selectedStore") : null
    if (cart && storeSelectedd) {
      this.setState({ isOpen: false })
      this.setState({ cartShowModal: true })

    }
    else {

      sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
      this.props.selectMenuForPickup(storeSelected, data);
      this.setState({ isOpen: false })
    }
    // this.props.history.push('/menu')
  }
  confirmDelivery = async () => {
    let { storeSelected } = this.props;
    let timezone = new Date().getTimezoneOffset() / 60;//offset in hours
    let timeZoneOffset = -(timezone);
    let today = new Date();
    let todayDay = today.getDay();
    let data = {
      timeZoneOffset: timeZoneOffset,
      day: todayDay
    }
    let cart = sessionStorage.getItem("cart") ? sessionStorage.getItem("cart") : null
    let storeSelectedd: any = sessionStorage.getItem("selectedStore") ? sessionStorage.getItem("selectedStore") : null
    if (cart && storeSelectedd) {
      this.setState({ isOpen: false })
      this.setState({ cartShowModal: true })
    }
    else {
      sessionStorage.setItem("selectedStore", JSON.stringify(storeSelected))
      this.props.findStoreforLSM(this.state.lat, this.state.lng, data)
      this.setState({ isOpen: false })
    }
    // this.props.saveCoordinates(obj);
    // this.props.selectChannel(channel);
    // this.props.history.push('/menu')
  }
  ValidateItemForStore = async () => {
    let selectedStore: any = sessionStorage.getItem("selectedStore")
    let store = JSON.parse(selectedStore)
    console.log("selected store", store.store_id)
    let obj = {
      mode: "online",
      store_id: this.props.storeSelected && this.props.storeSelected.store_id ? this.props.storeSelected.store_id : store.store_id,
      brand_id: 1
    };
    axios.post(`${BASE_URL}menu/getItemsForSpecificStore`, obj)
      .then((res) => {
        let specificStoreItems = res.data.menuData;
        let data: any = this.props.cart;
        let cart: any = JSON.parse(data);
        let dupCart: any = cart;
        let lsmItemRemove: any = [];
        for (let i = 0; i < cart.length; i++) {
          let found = 0;
          if (cart[i].item) {
            if (specificStoreItems.length > 0) {
              for (let j = 0; j < specificStoreItems.length; j++) {
                if (specificStoreItems[j].menu_item_id > 0) {
                  if (
                    cart[i].item.menu_item_id ==
                    specificStoreItems[j].menu_item_id
                  ) {
                    found = 1;
                    break;
                  }
                }
              }
              if (found == 0) {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            } else {
              lsmItemRemove.push(cart[i]);
              this.setState({ removeableLsmItemsArr: lsmItemRemove });
            }
          } else {
            if (specificStoreItems.length > 0) {
              for (let j = 0; j < specificStoreItems.length; j++) {
                if (
                  cart[i].combo.combo_id == specificStoreItems[j].combo_id
                ) {
                  found = 1;
                  break;
                }
              }
              if (found == 0) {
                lsmItemRemove.push(cart[i]);
                this.setState({ removeableLsmItemsArr: lsmItemRemove });
              }
            } else {
              lsmItemRemove.push(cart[i]);
              this.setState({ removeableLsmItemsArr: lsmItemRemove });
            }
          }
        }
        if (lsmItemRemove.length > 0) {
          this.setState({ showHide: true });
          sessionStorage.setItem("invalidItemFlag", 'true')
        }
      })
      .catch((err) => {
        if (err.response) {
          if (!err.response.data.success && err.response.data.message === "Items Not Found") {
            let data: any = this.props.cart;
            let cart: any = JSON.parse(data);
            let dupCart: any = cart;
            if (cart.length > 0) {
              this.setState({ removeableLsmItemsArr: cart, showHide: true });
              sessionStorage.setItem("invalidItemFlag", 'true')
            }
          }
        }
      });
  }

  removeLsmItems = () => {
    let { removeableLsmItemsArr } = this.state;
    const token = localStorage.getItem("token");
    let data: any = this.props.cart;
    let cart: any = JSON.parse(data);
    let dupCart: any = cart;
    for (let i = 0; i < cart.length; i++) {
      for (let j = 0; j < removeableLsmItemsArr.length; j++) {
        if (cart[i].item && removeableLsmItemsArr[j].item) {
          if (
            cart[i].item.menu_item_id ===
            removeableLsmItemsArr[j].item.menu_item_id
          ) {
            dupCart.splice(i, 1);
          }
        } else if (cart[i].combo && removeableLsmItemsArr[j].combo) {
          if (
            cart[i].combo.combo_id === removeableLsmItemsArr[j].combo.combo_id
          ) {
            dupCart.splice(i, 1);
          }
        }
      }
    }
    this.props.saveCart(dupCart);
    this.props.getCart();
    sessionStorage.setItem("invalidItemFlag", "false")
    this.setState({ showHide: false });
    if (cart.length === 0) {
      this.props.history.push({ pathname: "/menu", search: window.location.search });
    }
  };

  getCurrentPositionMark = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        console.log("current position call", position)
        this.setState({ position: { lat: position.coords.latitude, lng: position.coords.longitude } })
      })
    }
  };

  onPlaceClick = (place: any) => {
    console.log("onclick place", place)
    this.setState({ searchString: place.structured_formatting.main_text })
    Geocode.fromAddress(place.description)
      .then((json: any) => {
        var location = json.results[0].geometry.location;
        console.log(location)
        this.setState({ lat: location.lat, lng: location.lng, placesSuggestion: [], position: { lat: location.lat, lng: location.lng } })
      })
      .catch((error: any) => {
        // console.warn(error));
      });
  }

  handleInputChange = (event: any) => {
    let queryString = event.target.value;
    this.setState({ searchString: event.target.value })
    if (queryString.length > 0 && queryString.length < 50) {
      let obj = {
        address: queryString
      }
      const lang = localStorage.getItem('lang') || 'en';
      axios.post(`${BASE_URL}customer/autoComplete/${lang}/${queryString}`)
        .then((res: any) => {
          this.setState({ placesSuggestion: res.data.successResponse })
        })
        .catch((err: any) => {
          alert("something went wrong!!!")
        })
    }
    else {
      this.setState({ placesSuggestion: [] })
    }
  }

  exitModal = () => {
    this.setState({ infoOpen: false })
  }

  handleMapData = (obj: any) => {
    // to Get the values from the map component and set in state
    this.props.saveGuestAddress(obj.address);
    // this.props.findLocalStores(obj.lat, obj.lng);
    this.setState({
      selectedAddress: obj.address,
      searchString: obj.address,
      lat: obj.lat,
      lng: obj.lng,
      position: { lat: obj.lat, lng: obj.lng }
    });
    sessionStorage.setItem("changeLat", obj.lat)
    sessionStorage.setItem("changeLng", obj.lng)
  };
  inputfilterOnChange = (event: any) => {
    const { value } = event.target
    const { pickupStoresList } = this.props;
    let storesArr: any = [];
    this.setState({ searchString2: value })
    storesArr = pickupStoresList;
    const filterdata = storesArr.filter((store: any) => {
      return (
        store.address
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        store.city.toLowerCase().includes(value.toLowerCase()) ||
        store.store_name
          .toLowerCase()
          .includes(value.toLowerCase())
      );
    });
    this.setState({ filteredStores: filterdata })
  };
  selectPickupStore = (store: any) => {
    let filter = this.state.filteredStores
    let doc = document as any;
    var deActiveItem = doc.getElementsByClassName("item")
    for (let i = 0; i < filter.length; i++) {
      if (filter[i].store_id == store.store_id) {
        filter[i].is_clicked = true
        deActiveItem[i].classList.add("active-item1")
      } else {
        filter[i].is_clicked = false
        deActiveItem[i].classList.remove("active-item1")
      }
    }
    this.setState({ filteredStores: filter, use_pickup_map: false, directions: null })
    // let doc = document as any;
    // var deActiveItem = doc.getElementsByClassName("item")
    // for (let i = 0; i < deActiveItem.length; i++) {
    //   deActiveItem[i].classList.remove("active-item1")
    // }
    // let activeItem = (document as any).getElementById(store.store_id);
    // activeItem.classList.add("active-item1");
    this.setState({ pickupStoreSelectFlag: false })
    this.props.saveSelectStoreId(store);
    this.props.saveStore(store);
  }
  clearField = () => {
    let storesArr: any = this.props.pickupStoresList;
    this.setState({ filteredStores: storesArr, searchString2: "" })
  }
  removeSearchString = () => {
    this.setState({ searchString: "", placesSuggestion: [] })
  }
  cartCloseModal = () => {
    this.setState({
      cartShowModal: false
    })
  }

  render() {
    // let route = window.location.pathname
    // let cart:any = sessionStorage.getItem("cart")
    // if(_.isEmpty(cart) && (route =='/location' || route == '/menu' || route == '/cart' || route == '/payment')) {
    //   this.props.history.push("/menu")
    // }
    let page = window.location.pathname.split('/');
    let firstName = "";
    let token: any = localStorage.getItem("token");
    let decoded: any
    if (token) {
      decoded = jwt_decode(token);
      let userName: any = decoded?.customer?.login_name;
      firstName = userName && userName.split(" ")[0];
      let profile_pic: any = decoded?.customer?.profile_pic;
    }
    const lang = localStorage.getItem('lang') || 'en'
    // let profilePic = sessionStorage.getItem("profile_pic");
    // let profilePicSocal = sessionStorage.getItem("profile_pic_social");
    let data: any = this.props.cart;
    return (
      <>
        <header className="header">
          <UseTrackEvent location={window.location} setAddEvent={this.handleSetAddEvent} />
          <div className="header-top" style={{
            backgroundImage: "url(assets/images/website-background.png)",
            backgroundColor: "rgb(138, 36, 50)"
          }}>
            <div className="container custom-container">
              {this.state.showMenu ?
                <i onClick={this.openMobileMenu} className="fa fa-times close-icon"></i> :
                <img onClick={this.openMobileMenu} className="menu-icon" src="/assets/images/menu-icon.png" />
              }
              <div className="">
                <Link to="/" className="p-0 col-2">
                  <div className="tortilla-logo">
                    {lang == 'ar' ?
                      <img
                        onClick={this.activeScroll}
                        className="header-logo-styling header-logo-styling-pad"
                        src={process.env.PUBLIC_URL + "/assets/images/kyochonelogowhite.svg"}
                        alt="Kyochon Logo"
                      />
                      : <img
                        onClick={this.activeScroll}
                        className="header-logo-styling header-logo-styling-pad"
                        src={process.env.PUBLIC_URL + "/assets/images/kyochonelogowhite.svg"}
                        alt="Kyochon Logo"
                      />}
                  </div>
                </Link>
              </div>

              <div className="header-right header-dropdowns ml-0  ">
                <div className="header-dropdown dropdown-expanded d-none d-lg-block">
                  <div className="header-menu mt-2">
                    <ul>
                      {/* <li><Link className="header-link" to="/">{i18next.t('header.menu')}</Link></li> */}
                      <li><Link className="header-link" to={`/track-order`}>{i18next.t('header.track_order')} </Link></li>
                      {!token && (<li><Link className="" to='/checkout/contact'>
                        <img
                          style={{
                            // height: "38px",
                            width: "32px",
                            margin: "0",
                            borderRadius: "50%"
                          }}

                          src={"/assets/images/profile-circled.svg"}
                          alt="Profile Pic" />
                      </Link></li>)
                      }
                      {token && (<li>


                        <Link to={`/profile${window.location.search}`} className="header-link" style={{ cursor: "pointer" }}>
                          {`${i18next.t('common.hello')}, ${firstName}`}
                        </Link>
                        {/* :
                           <Link to="/login" className="header-link">
                            {i18next.t('header.header_reg_login')}
                          </Link> */}
                      </li>
                      )
                      }
                      {token &&
                        (<li className="profilepic" style={{ cursor: "pointer" }}>

                          {/* <div className='d-flex justify-content-center profilepic' > */}
                          {/* onClick={this.handleSubmit} */}

                          <Link to={`/profile${window.location.search}`}>
                            <img
                              style={{
                                // height: "38px",
                                width: "100%",
                                margin: "-6px 20px 0px 0px"
                              }}
                              src={decoded && decoded.customer && decoded.customer.profile_pic ? `${API_URL}${decoded.customer.profile_pic}` :
                                process.env.PUBLIC_URL + "/assets/images/profile-circled.svg"}
                              alt="Profile Pic" />


                          </Link>


                        </li>)
                      }

                      {
                        // page[1] !== '' && page[1] !== '/' && page[1] !== 'location' && page[1] !== 'payment' &&
                        page[1] === 'menu' &&
                        <li onClick={() => this.backToLanding()}>
                          {this.props.orderType != 'qr-Pickup' &&
                            <img
                              className="header-logo-styling"
                              style={{ width: '41px', cursor: 'pointer', margin: "-2px 0px 0 10px" }}
                              src={process.env.PUBLIC_URL + "/assets/images/Icon2.svg"}
                              alt="Kyochon Logo"
                            />
                          }
                        </li>
                      }

                      {/* {this.props.history.location.pathname !== "/" && */}

                      {window.location.pathname == '/' ? "" :
                        <li className="">
                          {this.renderCartButton(data)}
                        </li>
                      }
                      {lang == 'ar' ? (
                        <div style={{ cursor: "pointer" }} className="header-link" onClick={() => this.change("en")}>English</div>
                      ) :
                        <div style={{ cursor: "pointer" }} className="header-link" onClick={() => this.change("ar")}>العربية</div>
                      }
                      {/* } */}




                      {/* <li className="pt-2">
                        <select className="custom-select pull-right" value={lang} onChange={this.change}>
                          <option value='en'>English</option>
                          <option value='ar'>Arabic</option>
                        </select>
                      </li> */}
                    </ul>
                  </div>
                </div>
                <div className="navbar-right">
                  <div className="social-icons" >
                    {
                      this.props.history.location.pathname !== "/menu" &&
                      this.renderCartButton(data)
                    }
                    {
                      this.props.history.location.pathname == "/menu" &&
                      <span className="desktop_cart_icon"> {this.renderCartButton(data)}</span>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{
            top: '75px',
            position: 'relative'
          }}></div>
        </header>
        {this.state.showMenu &&
          <div style={{ position: 'relative' }}>
            <div className="botton-menu">
              <div className="bottom-menu-container">
                <ul onClick={() => { this.activeScroll() }}>
                  {/* <li className="li-tag"><Link className="header-link" to="/" {...(page[1] !== '') && { onClick: () => { this.activeScroll() } }}>{i18next.t('header.menu')}</Link></li> */}
                  {!token && (<li><Link className="" to='/checkout/contact'>
                    <img
                      style={{
                        // height: "38px",
                        width: "32px",
                        margin: "0 auto",
                        borderRadius: "50%",
                        marginTop: "10px"
                      }}

                      src={"/assets/images/profile-circled.svg"}
                      alt="Profile Pic" />
                  </Link></li>)
                  }
                  {token &&
                    <li className="li-tag text-center">
                      <Link to={`/profile${window.location.search}`} className={lang == "ar" ? "header-icon-arabic mr-2" : "header-icon mr-2"}>
                        {/* <i className="icon-user"></i> <span className="header-link"  {...(page[1] !== '') ? { onClick: () => { this.activeScroll() } } : { onClick: () => { this.setState({ showMenu: false }) } }}>  </span> */}
                        <img
                          style={{
                            // height: "38px",
                            width: "100%",
                            margin: "-1px 20px 0px 0px"
                          }}
                          src={decoded && decoded.customer && decoded.customer.profile_pic ? `${API_URL}${decoded.customer.profile_pic}` :
                            process.env.PUBLIC_URL + "/assets/images/profile-circled.svg"}
                          alt="Profile Pic" />

                      </Link>
                    </li>
                  }
                  {token &&
                    <li className="li-tag" >
                      <Link className="header-link ml-2" to={`/profile${window.location.search}`}>
                        {`${i18next.t('common.hello')},${firstName}`}</Link>
                    </li>
                  }
                  <li className="li-tag"><Link className="header-link" to={`/track-order`} {...(page[1] !== 'track-order') ? { onClick: () => { this.activeScroll() } } : { onClick: () => { this.setState({ showMenu: false }) } }}>{i18next.t('header.track_order')} </Link></li>
                  <li className="li-tag"><Link className="header-link" to={`/our-story${window.location.search}`} {...(page[1] !== 'our-story') ? { onClick: () => { this.activeScroll() } } : { onClick: () => { this.setState({ showMenu: false }) } }}>{i18next.t('footer.our_story')} </Link></li>
                  <li className="li-tag"><Link className="header-link" to={`/feedback${window.location.search}`} {...(page[1] !== 'feedback') ? { onClick: () => { this.activeScroll() } } : { onClick: () => { this.setState({ showMenu: false }) } }}>{i18next.t('footer.feedback')} </Link></li>
                  {
                    // page[1] !== '' && page[1] !== '/' && page[1] !== 'location' && page[1] !== 'payment' &&
                    page[1] === 'menu' &&
                    <li onClick={() => this.backToLanding()}>
                      {this.props.orderType != 'qr-Pickup' &&
                        <img
                          className="header-logo-styling mt-3"
                          style={{ width: '40px', cursor: 'pointer', margin: "auto" }}
                          src={process.env.PUBLIC_URL + "/assets/images/Icon2.svg"}
                          alt="Kyochon Logo"
                        />
                      }
                    </li>
                  }


                  {lang == 'ar' ? (
                    <li style={{ cursor: "pointer", color: "#fff" }} className="li-tag" onClick={() => this.change("en")}>Eng</li>
                  ) :
                    <li style={{ cursor: "pointer", color: "#fff" }} className="li-tag" onClick={() => this.change("ar")}>العربية</li>
                  }
                </ul>
              </div>
            </div>
          </div>
        }
        <Modal
          open={this.state.infoOpen}
          showCloseIcon={true}
          onClose={this.exitModal}
          center
        >
          <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
            <p style={{ fontSize: '16px' }} className="text-center">
              {!_.isEmpty(this.props.coupon) ?
                " You have some items in your cart, if you change your location your items and applied coupon will be discarded from the cart."
                : " You have some items in your cart, if you change your location your items will be discarded from the cart."
              }
            </p>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.onCloseModal}
                className="btn btn-sm btn-primary center custom-default-red-button">
                {i18next.t('checkoutPage.change_location')}
              </button>
            </div>
          </div>
        </Modal>
        <Modal
          open={!this.state.storeOpenFlag}
          showCloseIcon={false}
          onClose={this.exitModal}
          center
        >
          <div style={{ paddingTop: "10%", paddingBottom: "2%" }}>
            <p style={{ fontSize: '16px' }} className="text-center">

              {i18next.t('checkoutPage.Store_is_closed_at_this_moment')}
            </p>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={() => { this.setState({ storeOpenFlag: true }) }}
                className="btn btn-sm btn-primary center custom-default-red-button">
                {i18next.t('common.ok')}
              </button>
            </div>
          </div>
        </Modal>
        
        <BsModal
          open={this.state.cartShowModal}
          showCloseIcon={true}
          onClose={this.cartCloseModal}
          center
        >
          <div style={{ paddingTop: "5%", paddingBottom: "2%" }}>
            <p style={{ fontSize: '16px' }} className="text-center">
         
                 You have some items in your cart, if you change your location your items will be discarded from the cart.
              
            </p>
            <p style={{ fontSize: '16px' }} className="text-center">
         
            Do you want to continue?
              
            </p>

            <div style={{ textAlign: "center" }}>
              <button
                onClick={this.cartConfirmationCloseModal}
                className="btn btn-sm btn-primary center custom-default-red-button">
                {i18next.t('checkoutPage.change_location')}
              </button>
            </div>
          </div>
        </BsModal>   
        <BsModal className='modala' show={this.state.cartShowModal} onHide={this.cartCloseModal} >
          <BsModal.Body className='p-0'>
          <div style={{ paddingTop: "3%", paddingBottom: "2%" }}>
            <p style={{ fontSize: '16px',paddingLeft:"20px",paddingRight:"20px" }} className="text-center text-dark">
         
                 {i18next.t('checkoutPage.you_have')}
              
            </p>
            <p style={{ fontSize: '16px',paddingBottom:"10px" }} className="text-center text-dark">
         
            {i18next.t('checkoutPage.want_continue')}
              
            </p>

            <div style={{ textAlign: "center" ,display:"flex",justifyContent:"space-evenly"}}>
              <button
                onClick={this.cartConfirmationCloseModal}
                className="btn btn-sm btn-primary center custom-default-red-button">
                {i18next.t('checkoutPage.Yes')}
              </button>
              <button
                onClick={this.cartCloseModal}
                className="btn btn-sm btn-primary center custom-default-red-button">
                {i18next.t('checkoutPage.No')}
              </button>
            </div>
          </div>
          </BsModal.Body>

        </BsModal>
     
        <BsModal show={this.state.isOpen} onHide={this.closeModal}>
          <BsModal.Header closeButton>
            <div className="row w-100">
              <div className="col-12">
                <div className="d-flex justify-content-center">
                  <span onClick={() => this.handleSelectChannel("Delivery")} className={this.props.orderType == 'Delivery' ? "order-method selected br-delivery " : "order-method br-delivery"}>{lang == "en" ? "Delivery" : "توصيل"}</span>
                  <span onClick={() => this.handleSelectChannel("Pickup")} className={this.props.orderType == 'Pickup' ? "order-method selected br-pickup" : "order-method br-pickup"}>{lang == "en" ? "Pick up" : "الاستلام"}</span>
                </div>
              </div>
            </div>
            <BsModal.Title>
            </BsModal.Title>
          </BsModal.Header>
          <BsModal.Body style={{ overflowX: 'hidden' }}>
            {this.props.orderType == 'Delivery' &&
              <>
                <div className="search-bar">
                  <input placeholder={lang == 'en' ? "Enter your area" : "اكتب عنوانك"} autoComplete="off" name="autocomplete_query" value={this.state.searchString} className="autocomplete-input autoComplete" onChange={this.handleInputChange} />
                  {this.state.placesSuggestion.length > 0 &&
                    <div className="address-suggestion">
                      <ul>
                        {this.state.placesSuggestion.map((place: any) => {
                          return (
                            <>
                              <li
                                onClick={() => this.onPlaceClick(place)}
                                className="suggestion-item"
                              >
                                <span className="upper-text">
                                  <strong>
                                    {place.structured_formatting.main_text}
                                  </strong>
                                </span>
                                <span className="bottom-text">
                                  {place.structured_formatting.secondary_text}
                                </span>
                              </li>
                            </>
                          )
                        })
                        }
                      </ul>
                    </div>
                  }
                  {this.state.placesSuggestion && this.state.placesSuggestion.length > 0 &&
                    <i className="fa fa-times" style={{ width: '20px', fontSize: '18px' }} onClick={this.removeSearchString} aria-hidden="true"></i>
                  }
                  <img style={{ width: '30px', height: '30px' }} className="" title="Current Location" src="https://www.kyochonme.com/assets/images/MapPin.svg" alt="pin"
                    onClick={() => { this.getCurrentPositionMark() }}
                  />

                </div>
                <GoogleMap
                  onSetData={this.handleMapData}
                  location={this.state.position}
                  stores={this.props.pickupStoresList}
                  lat={this.state.lat}
                  lng={this.state.lng}
                />
              </>
            }
            {(this.props.orderType == 'Pickup' || this.props.orderType == 'qr-Pickup') &&
              <>
                <div className="searchDesign">
                  <div className="input-group W-90 d-flex align-items-center">
                    <input type="search"
                      name="searchstring"
                      value={this.state.searchString2}
                      className="form-control custom-input"
                      placeholder={lang == "en" ? "Search" : "يبحث"}
                      onChange={this.inputfilterOnChange}
                      style={{ height: '3rem', fontSize: '16px' }}
                    />
                  </div>
                  {/* <i onClick={this.clearField} className="fa fa-times close-icon-times"></i> */}
                </div>
                <div className="flat-list-container">
                  {this.state.filteredStores && this.state.filteredStores.map((store: any) => (
                    <div id={`${store.store_id}`} onClick={() => this.selectPickupStore(store)} className="item">
                      <span className="myprofile" style={{ fontSize: '17px', fontWeight: "bold" }}>{lang == 'ar' ? store.store_name_ar ? store.store_name_ar : store.store_name : store.store_name}</span>
                      <br />
                      <span className="myprofile">{store.address}</span>
                      {store && store.is_clicked && this.state.locationEnabled && <div className='mt-2' style={{ display: 'flex', justifyContent: 'center' }}>

                        <button disabled={this.state.pickupStoreSelectFlag} onClick={() => window.open("https://maps.google.com?q=" + store.lat + "," + store.lng)} className="btn get_direction_btn">
                          Open Map
                        </button>
                      </div>}
                    </div>
                  ))}
                </div>
              </>
            }
          </BsModal.Body>
          <BsModal.Footer className="justify-content-center">
            <Button onClick={this.confirmLocation} className="btn cart-button pickup-button btn btn-primary">
              {lang == "en" ? "CONFIRM" : "تأكيد"}
            </Button>
          </BsModal.Footer>
        </BsModal>
        <BsModal show={this.state.showHide} >
          <BsModal.Header closeButton>
            <BsModal.Title>
              {i18next.t("checkoutPage.items_not_available")}
            </BsModal.Title>
          </BsModal.Header>
          <BsModal.Body>
            <table className="table">
              {this.state.removeableLsmItemsArr &&
                this.state.removeableLsmItemsArr.map((item: any) => (
                  <tbody>
                    <tr className="product-row">
                      {/* <td>{fav.combo_id ? fav.combo_id : fav.menu_item_id}</td> */}
                      <td className="product-col">
                        <figure className="product-image-container">
                          <a className="product-image">
                            <img
                              src={`${API_URL}${item.selectedsize
                                ? item.selectedsize.image_url
                                : item.combo.image_url
                                }`}
                              alt="product"
                              style={{ width: "8rem" }}
                            />
                          </a>
                        </figure>
                      </td>
                      <td>
                        {item.item && item.item.item_name}
                        {item.combo && item.combo.combo_name}
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </BsModal.Body>
          <BsModal.Footer className="justify-content-center">
            <Button
              className="btn custom-default-red-button"
              onClick={this.removeLsmItems}
            >
              {i18next.t("checkoutPage.confirm")}
            </Button>
          </BsModal.Footer>
        </BsModal>
      </>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    groupList: state.login.groupList,
    cart: state.login.cartData,
    GroupName: state.login.GroupName,
    groupsData: state.login.groupsData,
    storeOpenFlag: state.login.storeOpenFlag,
    coupon: state.login.coupon,
    stores: state.login.storesList,
    storeSelected: state.login.storeSelected,
    selectStoreId: state.login.selectStoreId,
    orderType: state.login.orderType,
    pickupStoresList: state.login.pickupStoresList,
    menuStoreId: state.login.menuStoreId
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logoutCustomer: function () {
      dispatch(logoutCustomer());
    },
    // findLocalStores: function (lat: any, long: any) {
    //   dispatch(findLocalStores(lat, long));
    // },
    // menuCombosList: function (data: any) {
    //   dispatch(menuCombosList(data));
    // },
    getCart: function (history?: any) {
      dispatch(getCart(history));
    },
    menuGroupsList: function (store_id: any, data: any) {
      dispatch(menuGroupsList(store_id, data));
    },
    menuItemsListByGroupsID: function (data: any) {
      dispatch(menuItemsListByGroupsID(data));
    },
    saveCart: function (cart: any[]) {
      dispatch(saveCart(cart));
    },
    updateLSM: function (status: any) {
      dispatch(updateLSM(status));
    },
    setTabName: (tabName: any) => {
      dispatch(setTabName(tabName));
    },
    saveurlMethod: function (url: any) {
      dispatch(saveurlMethod(url));
    },
    findStoreforLSM: function (lat: any, long: any, data: any) {
      dispatch(findStoreforLSM(lat, long, data));
    },
    UpdateMenuItems: function (data: any) {
      dispatch(UpdateMenuItems(data))
    },
    TopDealsHome: function (store_id: any) {
      dispatch(TopDealsHome(store_id));
    },
    TopList: function (store_id: any) {
      dispatch(TopList(store_id));
    },
    menuGroupsListHome: function (store_id: any, data: any) {
      dispatch(menuGroupsListHome(store_id, data));
    },
    TopItemsList: function (store_id: any) {
      dispatch(TopItemsList(store_id));
    },
    menuHeroItems: (store_id: any) => {
      dispatch(menuHeroItems(store_id))
    },
    selectMenuForPickup: (store: any, data: any) => {
      dispatch(selectMenuForPickup(store, data))
    },
    saveStore: (store: any) => {
      dispatch(saveStore(store));
    },
    saveSelectStoreId: (store: any) => {
      dispatch(saveSelectStoreId(store));
    },
    saveOrderType: function (type: any) {
      dispatch(saveOrderType(type));
    },
    saveGuestAddress: function (address: any) {
      dispatch(saveGuestAddress(address));
    },
    PickupStoresList: (lat: any, lng: any, searchString: any) => {
      dispatch(PickupStoresList(lat, lng, searchString))
    },
    findStoreforLSMWithoutMenuApiCall: (lat: any, lng: any, searchString: any) => {
      dispatch(findStoreforLSMWithoutMenuApiCall(lat, lng, searchString))
    },
    selectMenuForPickupWithoutMenuApiCall: (store: any, data: any) => {
      dispatch(selectMenuForPickupWithoutMenuApiCall(store, data))
    },
    PickupStoreListByDistance: (data: any) => {
      dispatch(PickupStoreListByDistance(data))
    },
    getBrandDetails: () => { dispatch(getBrandDetails()) },
    lastorder: () => {
      dispatch(lastOrder())
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
