import React, { Component } from "react";
// import LoginComponent from "../components/Login/index";
import Header from "../components/Header/index";
import Footer from "../components/Footer/index";
import NutritionalInfoPage from "../components/NutritionalInfo";
import { Redirect } from "react-router-dom";

class NutritionalInfo extends Component<{history:any},{}> {
    componentDidMount() {
        // document.title = "Nutritional Info - Tortilla";
        document.title = "Nutritional Info - Kyochon"
    }
    render() {
        // if (!localStorage.mainToken) {
        //     return <Redirect to="/landing" />
        //   }
        return (
            <div className="page-wrapper">
                <div id="page-wrap">
                    <Header history={this.props.history}/>
                    <NutritionalInfoPage />
                    <Footer />
                </div>
            </div>
        );
    }
}

export default NutritionalInfo;