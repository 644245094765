import React, { Component } from "react";
import { OtpProps, OtpStates } from "../Interface/loginInterface";
import { connect } from "react-redux";
import "./style.css";
import { changePasswordOtp, forgotPassword, generateOtp, updateForgotTab, ValidateOtp } from "../../Redux/Actions";
import { toast } from "react-toastify";
import i18next from "i18next";
import "react-toastify/dist/ReactToastify.css";
import PhoneInput from "react-phone-input-2";

toast.configure();

class index extends Component<OtpProps, OtpStates> {
  constructor(props: any) {
    super(props);
    this.state = {
      email: "",
      phone: "",
      otp: "",
      isValidEmail: true,
      isValidPhone: false,
      isValidPass: false,
      newPass: "",
      confirmPass: "",
      changePassMsg: "",
      list: '1',
      passError: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  componentWillUnmount() {
    this.setState({
      list: '1',
      email: '',
      phone: '',
      otp: '',
      isValidEmail: true,
      isValidPhone: false,
      isValidPass: false,
      newPass: '',
      confirmPass: '',
      changePassMsg: ''
    })
    let tab = '1'
    this.props.updateForgotTab(tab);
  }
  handleSubmit = () => {
    const { phone } = this.state;
    let str_phone_number = phone
    // if (str_phone_number.charAt(0) === '9') {
    //   str_phone_number = str_phone_number.substring(2);
    //   str_phone_number = "0" + str_phone_number
    // }
    this.props.generateOtp(str_phone_number);
  };
  handleSubmitOTP = () => {
    const { phone, otp } = this.state;
    let str_phone_number = phone
    // if (str_phone_number.charAt(0) === '9') {
    //   str_phone_number = str_phone_number.substring(2);
    //   str_phone_number = "0" + str_phone_number
    // }
    let data = {
      phone: str_phone_number,
      otp: otp,
    }
    this.props.ValidateOtp(data)
  }
  handleInputChange(event: { target: { name: any; value: any } }) {
    if (event.target.name === 'newPass') {
      if (event.target.value.length < 8) {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: false,
        });
      }
      else {
        this.setState({
          [event.target.name]: event.target.value,
          isValidPass: true,
        });
      }
    }
    else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }
  }
  isUserReady = () => {
    let { phone, isValidPhone } = this.state;
    return (phone !== '' && (phone.length > 10 || phone.length <= 15));
  }

  isUserPasswordReady = () => {
    let { isValidPass, phone, newPass, confirmPass } = this.state;
    let validateSignal = false;
    if (newPass !== "" && confirmPass !== "" && isValidPass) {
      validateSignal = true;
    }
    return validateSignal;
  }
  checkPhone = (phone: any) => {
    if (phone) {
      // var phoneno = /^\+?([0-9]{3})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{5})$/;
      // var mobileno = /^((\\+91-?)|0)?[0-9]{12}$/;
      var phoneno = /^(?:\971|0)(?:2|3|4|6|7|9|50|51|52|55|56)[0-9]{7}$/;  // uae mobile numbers
      if (phone.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    }
  };
  handleSubmitPass = () => {
    let { newPass, confirmPass, phone } = this.state;
    let str_phone_number = phone
    // if(str_phone_number.charAt(0) === '9'){
    //   str_phone_number = str_phone_number.substring(2);
    //   str_phone_number = "0" + str_phone_number
    // }
    let data = {
      password: newPass,
      phone: str_phone_number
    }
    if (newPass == confirmPass) {
      this.props.changePasswordOtp(data)
    } else {
      this.setState({ changePassMsg: "Password don't match", passError: true });
    }
  }
  handleOnChange = (value: any, data: any) => {
    this.setState({ phone: value }, () => {
      console.log(this.state.phone);
    });
  };

  checkPassword = (pwd: any) => {
    if (pwd.length < 8) {
      return false;
    }
    else {
      return true;
    }
  };

  render() {
    return (
      <div className="main">
        <div className="container mb-5">
          <br />
          <div className="row">
            {
              this.props.switchTab === "1"
                ? this.PhoneFields()
                : this.props.switchTab === "2"
                  ? this.OtpRegister()
                  : this.props.switchTab === "3"
                    ? this.UpdatePassword()
                    : null
            }
          </div>
          <br />
        </div>
      </div>
    );
  }
  PhoneFields = () => {
    return (
      <div className="col-md-12 col-md-offset-4">
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-lock fa-4x"></i>
              </h3>
              <h2 className="text-center">{i18next.t('forgotPass.forgot_pass')}</h2>
              <p>{i18next.t('forgotPass.forgot_pass_text')}</p>
              <div className="login-form-1">
                <PhoneInput
                  inputProps={{
                    name: 'phone',
                    required: true,
                    autoFocus: true
                  }}
                  country={'ae'}
                  disableDropdown={true}
                  onlyCountries={['ae']}
                  countryCodeEditable={false}
                  // // masks={{pk: ' (...) ..-..-...',}}
                  // // prefix={'+'}
                  // placeholder="3..-......."
                  value={this.state.phone}
                  onChange={this.handleOnChange}
                />
                {(!this.checkPhone(this.state.phone) && this.state.phone !== '') &&
                  <small className="form-text text-danger">{i18next.t('errorMsg.invalid_phone')}</small>
                }
              </div>
              <br />
              <div className="login-form-1">
                <button id='roundb' disabled={!this.isUserReady()} onClick={this.handleSubmit} type="button" className="btn cart-button mt-2">{i18next.t('forgotPass.proceed')}</button>

                <br />
                {this.props.isReset === true && (
                  <div>
                    <br />
                    <div className="alert alert-success alert-dismissible">
                      <button
                        id="roundb"
                        type="button"
                        className="close"
                        data-dismiss="alert"
                      >
                        &times;
                      </button>
                      {this.props.respMessage}
                    </div>
                  </div>
                )}
                {this.props.isReset === false && (
                  <div>
                    <br />
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        id="roundb"
                        type="button"
                        style={{ padding: "unset" }}
                        className="close"
                        data-dismiss="alert"
                      >
                        &times;
                      </button>
                      {this.props.respMessage}
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  OtpRegister = () => {
    return (
      <div className="col-md-12 col-md-offset-4">
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-lock fa-4x"></i>
              </h3>
              <h2 className="text-center">{i18next.t('forgotPass.enter_otp')}</h2>
              <p>{i18next.t('forgotPass.enter_otp_text')}</p>
              <div className="login-form-1">
                <input id="round" defaultValue={''} value={this.state.otp} className="form-control" style={{ fontSize: "15px" }} size={40} type="tel" placeholder={i18next.t('forgotPass.enter_otp')}maxLength={11} name="otp" onChange={this.handleInputChange} required />
              </div>
              <br />
              <div className="login-form-1">
                {/* <input
                      id="roundb"
                      name="recover-submit"
                      style={{
                        width: "145%",
                        marginRight: "45px",
                        marginLeft: "-45px",
                      }}
                      onClick={this.handleSubmit}
                      className="btn btn-lg btn-danger"
                      value="Reset Password"
                      type="submit"
                    /> */}
                <button id='roundb' disabled={!this.isUserReady()} onClick={this.handleSubmitOTP} type="button" className="btn cart-button mt-2">{i18next.t('forgotPass.proceed')}</button>

                <br />

                {this.props.isValidOtp === false && (
                  <div>
                    <br />
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        id="roundb"
                        type="button"
                        style={{ padding: "unset" }}
                        className="close"
                        data-dismiss="alert"
                      >
                        &times;
                      </button>
                      {this.props.OtprespMessage}
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  UpdatePassword = () => {
    return (
      <div className="col-md-12 col-md-offset-4">
        <div className="panel panel-default">
          <div className="panel-body">
            <div className="text-center">
              <h3>
                <i className="fa fa-lock fa-4x"></i>
              </h3>
              <h2 className="text-center">{i18next.t('forgotPass.change_password')}</h2>
              <div className="login-form-1">
                <input id="round" style={{ fontSize: "15px" }} defaultValue="" size={40} type="password" className="form-control" placeholder={i18next.t('forgotPass.new_password')} name="newPass" onChange={this.handleInputChange} required />
                {(!this.checkPassword(this.state.newPass) && this.state.newPass !== '') &&
                  <small className="form-text text-danger">{i18next.t('errorMsg.password_length')}</small>
                }
                <input id="round" style={{ fontSize: "15px" }} defaultValue="" size={40} type="password" className="form-control" placeholder={i18next.t('forgotPass.confirm_password')} name="confirmPass" onChange={this.handleInputChange} required />

              </div>
              <br />
              <div className="login-form-1">
                <button id='roundb' disabled={!this.isUserPasswordReady()} onClick={this.handleSubmitPass} type="button" className="btn cart-button">{i18next.t('forgotPass.reset_password')}</button>

                <br />

                {this.state.passError === true && (
                  <div>
                    <br />
                    <div className="alert alert-danger alert-dismissible">
                      <button
                        id="roundb"
                        type="button"
                        style={{ padding: "unset" }}
                        className="close"
                        data-dismiss="alert"
                      >
                        &times;
                      </button>
                      {this.state.changePassMsg}
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }

}
const mapStateToProps = (state: any) => {
  return {
    isReset: state.login.isReset,
    respMessage: state.login.respMessage,
    switchTab: state.login.switchTab,
    isValidOtp: state.login.isValidOtp,
    OtprespMessage: state.login.OtprespMessage
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    generateOtp: function (phone: any) {
      dispatch(generateOtp(phone));
    },
    ValidateOtp: function (data: any) {
      dispatch(ValidateOtp(data));
    },
    changePasswordOtp: function (data: any) {
      dispatch(changePasswordOtp(data));
    },
    updateForgotTab: function (tab: any) {
      dispatch(updateForgotTab(tab))
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(index);
